// Types
import CatalogMassiveUploadTypes from '../../types/CatalogMassiveUploadTypes'

const initialState: CatalogMassiveUploadTypes.State = {
  register: {
    type: 'Price',
    name: {
      name: 'name',
      label: 'Nombre de carga',
      placeholder: '',
      value: '',
      errors: []
    },
    isStartButtonEnable: false,
    searchProductInput: {
      name: 'searchProductInput',
      label: '',
      placeholder: 'Buscar producto',
      value: '',
      errors: []
    },
    isModalConfirmationVisible: false,
    productsToUpload: []
  }
}

export default initialState
