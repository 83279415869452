// Assets
import DeliveryWindowsTypes from '../../../types/DeliveryWindowsTypes'
import GeneralTypes from '../../../types/GeneralTypes'
import finisedIcon from '../../assets/images/finished-icon.svg'
import loadProcessIcon from '../../assets/images/load-process-icon.svg'
import withErrorsIcon from '../../assets/images/with-error-icon.svg'

export const segmentOptions = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' }
]

export const deadlineDayOptions = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' }
]

export const timeZoneOptions = [
  { value: '-05:00', label: '-05:00' },
  { value: '-06:00', label: '-06:00' },
  { value: '-07:00', label: '-07:00' },
  { value: '-08:00', label: '-08:00' }
]

export const maximunFileSize: { size: number; unit: GeneralTypes.UnitOfByte } =
  {
    size: 750,
    unit: 'kb'
  }

export const filesLogIconography = {
  LOAD: loadProcessIcon,
  PROCESS: loadProcessIcon,
  WITHERROR: withErrorsIcon,
  FINISHED: finisedIcon
}

export const daysOptions = [
  {
    value: '',
    label: 'Días de entrega'
  },
  {
    value: 'sunday',
    label: 'Domingo'
  },
  {
    value: 'monday',
    label: 'Lunes'
  },
  {
    value: 'tuesday',
    label: 'Martes'
  },
  {
    value: 'wednesday',
    label: 'Miércoles'
  },
  {
    value: 'thursday',
    label: 'Jueves'
  },
  {
    value: 'friday',
    label: 'Viernes'
  },
  {
    value: 'saturday',
    label: 'Sábado'
  }
]

export const XLSXHeaders = [
  'modelorama id',
  'cliente id',
  'dias de entrega',
  'fecha limite',
  'hora de caducidad',
  'zona horaria',
  'segmento'
]

export type DaysOptionsType = typeof daysOptions[number]

export const deliveryStatus: { [x: string]: DeliveryWindowsTypes.Status } = {
  ACTIVE: 'Activo',
  PAUSE: 'Pausado'
}
