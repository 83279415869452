/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Packages
import React from 'react'
import { Amplify } from 'aws-amplify'
import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale/es_ES'
import 'moment/locale/es-mx'
import 'antd/dist/antd.css'
import client from './src/apolloConfig/client'
import { ApolloProvider } from '@apollo/client'

// Components
import WithDataContextProvider from './src/higherOrderComponents/WithDataContextProvider'
import WithFluxContextProviders from './src/higherOrderComponents/WithFluxContextProviders'
import WithAuthContextProvider from './src/higherOrderComponents/WithAuthContextProvider'

// Assets
import config from './src/data/env'

export const wrapRootElement = ({ element }) => (
  <WithDataContextProvider>
    <WithFluxContextProviders>
      <ConfigProvider locale={locale}>
        <ApolloProvider client={client}>
          <WithAuthContextProvider>{element}</WithAuthContextProvider>
        </ApolloProvider>
      </ConfigProvider>
    </WithFluxContextProviders>
  </WithDataContextProvider>
)

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const {
    location: { pathname: currentPathname }
  } = routerProps

  if (prevRouterProps) {
    const {
      location: { pathname: prevPathname }
    } = prevRouterProps
    return currentPathname !== prevPathname
  }

  return true
}

export const wrapPageElement = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.amplifyUserPoolId,
        userPoolClientId: config.amplifyUserPoolWebClientId
      }
    }
  })
}
