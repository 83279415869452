exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-catalog-bulkload-index-tsx": () => import("./../../../src/pages/catalog/bulkload/index.tsx" /* webpackChunkName: "component---src-pages-catalog-bulkload-index-tsx" */),
  "component---src-pages-catalog-inactives-index-tsx": () => import("./../../../src/pages/catalog/inactives/index.tsx" /* webpackChunkName: "component---src-pages-catalog-inactives-index-tsx" */),
  "component---src-pages-catalog-index-tsx": () => import("./../../../src/pages/catalog/index.tsx" /* webpackChunkName: "component---src-pages-catalog-index-tsx" */),
  "component---src-pages-catalog-massive-index-tsx": () => import("./../../../src/pages/catalog/massive/index.tsx" /* webpackChunkName: "component---src-pages-catalog-massive-index-tsx" */),
  "component---src-pages-catalog-processes-index-tsx": () => import("./../../../src/pages/catalog/processes/index.tsx" /* webpackChunkName: "component---src-pages-catalog-processes-index-tsx" */),
  "component---src-pages-catalog-register-index-tsx": () => import("./../../../src/pages/catalog/register/index.tsx" /* webpackChunkName: "component---src-pages-catalog-register-index-tsx" */),
  "component---src-pages-catalog-update-index-tsx": () => import("./../../../src/pages/catalog/update/index.tsx" /* webpackChunkName: "component---src-pages-catalog-update-index-tsx" */),
  "component---src-pages-delivery-windows-index-tsx": () => import("./../../../src/pages/delivery-windows/index.tsx" /* webpackChunkName: "component---src-pages-delivery-windows-index-tsx" */),
  "component---src-pages-devices-index-tsx": () => import("./../../../src/pages/devices/index.tsx" /* webpackChunkName: "component---src-pages-devices-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-mam-index-tsx": () => import("./../../../src/pages/mam/index.tsx" /* webpackChunkName: "component---src-pages-mam-index-tsx" */),
  "component---src-pages-orders-index-tsx": () => import("./../../../src/pages/orders/index.tsx" /* webpackChunkName: "component---src-pages-orders-index-tsx" */),
  "component---src-pages-partners-create-partner-index-tsx": () => import("./../../../src/pages/partners/create-partner/index.tsx" /* webpackChunkName: "component---src-pages-partners-create-partner-index-tsx" */),
  "component---src-pages-partners-details-partner-index-tsx": () => import("./../../../src/pages/partners/details-partner/index.tsx" /* webpackChunkName: "component---src-pages-partners-details-partner-index-tsx" */),
  "component---src-pages-partners-index-tsx": () => import("./../../../src/pages/partners/index.tsx" /* webpackChunkName: "component---src-pages-partners-index-tsx" */),
  "component---src-pages-partners-logbook-partner-index-tsx": () => import("./../../../src/pages/partners/logbook-partner/index.tsx" /* webpackChunkName: "component---src-pages-partners-logbook-partner-index-tsx" */),
  "component---src-pages-partners-upload-partner-index-tsx": () => import("./../../../src/pages/partners/upload-partner/index.tsx" /* webpackChunkName: "component---src-pages-partners-upload-partner-index-tsx" */),
  "component---src-pages-password-index-tsx": () => import("./../../../src/pages/password/index.tsx" /* webpackChunkName: "component---src-pages-password-index-tsx" */),
  "component---src-pages-promotions-bulk-load-errors-index-tsx": () => import("./../../../src/pages/promotions/bulk-load/errors/index.tsx" /* webpackChunkName: "component---src-pages-promotions-bulk-load-errors-index-tsx" */),
  "component---src-pages-promotions-bulk-load-index-tsx": () => import("./../../../src/pages/promotions/bulk-load/index.tsx" /* webpackChunkName: "component---src-pages-promotions-bulk-load-index-tsx" */),
  "component---src-pages-promotions-index-tsx": () => import("./../../../src/pages/promotions/index.tsx" /* webpackChunkName: "component---src-pages-promotions-index-tsx" */),
  "component---src-pages-promotions-input-index-tsx": () => import("./../../../src/pages/promotions/input/index.tsx" /* webpackChunkName: "component---src-pages-promotions-input-index-tsx" */),
  "component---src-pages-promotions-register-index-tsx": () => import("./../../../src/pages/promotions/register/index.tsx" /* webpackChunkName: "component---src-pages-promotions-register-index-tsx" */),
  "component---src-pages-promotions-update-index-tsx": () => import("./../../../src/pages/promotions/update/index.tsx" /* webpackChunkName: "component---src-pages-promotions-update-index-tsx" */),
  "component---src-pages-stores-create-store-index-tsx": () => import("./../../../src/pages/stores/create-store/index.tsx" /* webpackChunkName: "component---src-pages-stores-create-store-index-tsx" */),
  "component---src-pages-stores-details-store-index-tsx": () => import("./../../../src/pages/stores/details-store/index.tsx" /* webpackChunkName: "component---src-pages-stores-details-store-index-tsx" */),
  "component---src-pages-stores-index-tsx": () => import("./../../../src/pages/stores/index.tsx" /* webpackChunkName: "component---src-pages-stores-index-tsx" */),
  "component---src-pages-suggested-products-index-tsx": () => import("./../../../src/pages/suggested-products/index.tsx" /* webpackChunkName: "component---src-pages-suggested-products-index-tsx" */),
  "component---src-pages-users-create-user-index-tsx": () => import("./../../../src/pages/users/create-user/index.tsx" /* webpackChunkName: "component---src-pages-users-create-user-index-tsx" */),
  "component---src-pages-users-edit-user-index-tsx": () => import("./../../../src/pages/users/edit-user/index.tsx" /* webpackChunkName: "component---src-pages-users-edit-user-index-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */)
}

