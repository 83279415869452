const promotionsCategory = {
  DISCOUNTS_PERCENTAGE: {
    translateES: 'Porcentaje de descuento'
  },
  DISCOUNTS_MONEY: {
    translateES: 'Descuento en dinero'
  },
  DISCOUNTS_COMBINED: {
    translateES: 'Descuentos combinados'
  },
  DISCOUNTS_LOYALTY: {
    translateES: 'Descuentos lealtad'
  }
}

export type PromotionsCategoryType = typeof promotionsCategory

export default promotionsCategory
