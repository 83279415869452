// Assets
import * as actionTypes from '../actionTypes'

// Types
import FluxTypes from '../../../types/FluxTypes'

export const setCatalogMassiveUploadSearchProductInput: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_CATALOG_MASSIVE_UPLOAD_SEARCH_PRODUCT_INPUT,
    value
  })

export const setCatalogMassiveUploadType: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_CATALOG_MASSIVE_UPLOAD_TYPE,
  value
})

export const setCatalogMassiveUploadName: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_CATALOG_MASSIVE_UPLOAD_NAME,
  value
})

export const setCatalogMassiveUploadStartButton: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_CATALOG_MASSIVE_UPLOAD_START_BUTTON,
  value
})

export const setCatalogMassiveUploadModalConfirmation: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_CATALOG_MASSIVE_UPLOAD_MODAL_CONFIRMATION,
    value
  })

export const setCatalogMassiveUploadProductsToUpload: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_CATALOG_MASSIVE_UPLOAD_PRODUCTS_TO_UPLOAD,
    value
  })
