import { InputNumberProps } from 'antd'
import { formmater } from '../../../utils/general'
import PromotionsTypes from '../../../types/PromotionsTypes'

export enum DiscountTypes {
  PERCENTAGE = 'PERCENTAGE',
  PRICE = 'PRICE'
}

export enum DiscountTypesEsp {
  PERCENTAGE = 'Descuento',
  PRICE = 'Precio'
}

export const discountTypesLabel = {
  0: 'Selecciona un tipo de descuento',
  PRICE: 'Asigna un precio',
  PERCENTAGE: 'Asigna un porcentaje de descuento'
}

export const discountTypesOptions: {
  key: string
  label: string
}[] = [
  { key: '0', label: 'Elige una opción' },
  { key: DiscountTypes.PRICE, label: 'Por precio fijo' },
  { key: DiscountTypes.PERCENTAGE, label: 'Por porcentaje' }
]

export const extraLevelsAllowed = 7

export const inputNumberPropsDefault: InputNumberProps = {
  step: '1',
  min: '1',
  precision: 0,
  stringMode: true,
  size: 'large'
}

export const inputNumberPropsInPrice: InputNumberProps = {
  formatter: formmater,
  min: '0',
  precision: 2
}

export const inputNumberPropsInPercent: InputNumberProps = {
  formatter: (value) =>
    `${String(value).replace(/\d(?=(\d{3})+(\.|$))/g, '$&,')}%`,
  min: '0',
  max: '100',
  precision: 2
}

export const inputNumberPropsBasedOnDiscountType = {
  0: {},
  PRICE: inputNumberPropsInPrice,
  PERCENTAGE: inputNumberPropsInPercent
}

export enum LabelsProductsType {
  BASE_PRODUCT = 'Producto base',
  COMPLEMENT_PRODUCT = 'Producto complemento'
}

export enum PromotionType {
  DISCOUNTS_PERCENTAGE = 'DISCOUNTS_PERCENTAGE',
  DISCOUNTS_MONEY = 'DISCOUNTS_MONEY',
  DISCOUNTS_COMBINED = 'DISCOUNTS_COMBINED',
  DISCOUNTS_LOYALTY = 'DISCOUNTS_LOYALTY'
}

export const editedTypes: Record<
  Exclude<
    PromotionsTypes.Category,
    'DISCOUNTS_PERCENTAGE' | 'DISCOUNTS_LOYALTY'
  >,
  string
> = {
  DISCOUNTS_MONEY: 'combo-promotion-update',
  DISCOUNTS_COMBINED: 'bundle-promotion-update'
}
