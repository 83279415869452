const catalogFormConstants = {
  mesurementUnits: [
    { label: 'Unidad de medida', value: '' },
    { label: 'Mililitros', value: 'ml' },
    { label: 'Litros', value: 'l' },
    { label: 'Gramos', value: 'gr' },
    { label: 'Pieza', value: 'pz' },
    { label: 'Piezas', value: 'pzs' },
    { label: 'Paquete', value: 'paq' },
    { label: 'Onzas', value: 'oz' }
  ],
  partnerTypes: [
    { label: 'Tipo de socio', value: '' },
    { label: 'Partner', value: 'p' },
    { label: 'No Partner', value: 'np' },
    { label: 'Modelo', value: 'mod' }
  ],
  brands: [
    { label: 'Marcas', value: '' },
    { label: 'Acqua Panna', value: 'ap' },
    { label: 'Barrilito', value: 'bar' },
    { label: 'Beck´s', value: 'bec' },
    { label: 'Bocanegra Dunkel', value: 'bocd' },
    { label: 'Bocanegra Pilsner', value: 'bocp' },
    { label: 'Bocanegra Wheat', value: 'bocw' },
    { label: 'Bravucona', value: 'brav' },
    { label: 'Bud Light', value: 'budl' },
    { label: 'Budweiser', value: 'budw' },
    { label: 'Corona Cero', value: 'cc' },
    { label: 'Corona Extra', value: 'ce' },
    { label: 'Corona Light', value: 'cl' },
    { label: 'Cucapa Border', value: 'cucb' },
    { label: 'Cucapa Chupacabras', value: 'cucch' },
    { label: 'Cucapa Clasica', value: 'cuccl' },
    { label: 'Cucapa Green Card', value: 'cucg' },
    { label: 'Cucapa Honey', value: 'cuch' },
    { label: 'Cucapa Hopulin', value: 'cuchop' },
    { label: 'Cucapa Kolsh 57', value: 'cuck' },
    { label: 'Cucapa Migra', value: 'cucm' }
  ],
  providers: [
    { label: 'Proveedores', value: '' },
    { label: 'Grupo Modelo', value: 'gm' },
    { label: 'Red Bull', value: 'rb' },
    { label: '3 Estrellas', value: '3e' },
    { label: 'Act II', value: 'aii' },
    { label: 'Adams', value: 'ad' },
    { label: 'Adorable', value: 'ado' },
    { label: 'Agua Tina', value: 'at' },
    { label: 'Alcatraz', value: 'alc' },
    { label: 'Alpura', value: 'alp' },
    { label: 'Arizona', value: 'arz' },
    { label: 'Aurrera', value: 'aur' },
    { label: 'Balmoro', value: 'bal' },
    { label: 'Barcel', value: 'bar' },
    { label: 'Bayer', value: 'bay' },
    { label: 'Beer House', value: 'bh' },
    { label: 'Benson & Hedges', value: 'byh' },
    { label: 'Betamex', value: 'bet' },
    { label: 'Bic', value: 'bic' },
    { label: 'Big Roll', value: 'br' },
    { label: 'Bimbo', value: 'bim' }
  ],
  categories: [
    { label: 'Categorias', value: '' },
    { label: 'Cerveza', value: 'ml' },
    { label: 'Cerveza Craft', value: 'l' },
    { label: 'Cerveza Importada', value: 'gr' },
    { label: 'Agua', value: 'pz' },
    { label: 'Refresco', value: 'pzs' },
    { label: 'Alimento', value: 'paq' },
    { label: 'Bebida Alcohólica', value: 'ml' },
    { label: 'Bebida Energética', value: 'l' },
    { label: 'Botana', value: 'gr' },
    { label: 'Café', value: 'pz' },
    { label: 'Cigarros', value: 'pzs' },
    { label: 'Complemento', value: 'paq' },
    { label: 'Electrónico', value: 'ml' },
    { label: 'Galletas', value: 'l' },
    { label: 'Higiene', value: 'gr' },
    { label: 'Jugo', value: 'pz' },
    { label: 'Lácteo', value: 'pzs' },
    { label: 'Limpieza', value: 'paq' }
  ],
  unitDrive: [
    { label: 'Unidad de manejo', value: '' },
    { label: 'Pieza', value: 'ml' },
    { label: '6 Pack', value: 'l' },
    { label: '12 Pack', value: 'gr' },
    { label: '18 Pack', value: 'pz' },
    { label: '24 Pack', value: 'pzs' }
  ],
  cupos: [
    { label: 'Cupo', value: '' },
    { label: 'Bote', value: '0' },
    { label: 'Media', value: '0' },
    { label: 'Media Chaparra', value: '0' },
    { label: 'Mega', value: '0' },
    { label: 'Cuarto', value: '0' },
    { label: 'Familiar', value: '0' },
    { label: 'Latón', value: '0' },
    { label: 'Botella Plástico', value: '0' },
    { label: 'Tetrapack', value: '0' },
    { label: 'Empaque', value: '0' },
    { label: 'Botella Vidrio', value: '0' },
    { label: 'Lata', value: '0' }
  ],
  retornable: [
    { label: 'Tipo de producto', value: '' },
    { label: 'Retornable', value: 'Y' },
    { label: 'No retornable', value: 'N' }
  ]
}

export default catalogFormConstants
