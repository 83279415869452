// Packages
import { createContext } from 'react'

// Types
import FluxTypes from '../../types/FluxTypes'

const fluxActionsContext: FluxTypes.ActionsContext = createContext({
  actionCreators: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
})

export default fluxActionsContext
