// Packages
import moment from 'moment'

// Types
import PromotionsStoreTypes from '../../types/PromotionsStoreTypes'

const today = new Date()

const initialState: PromotionsStoreTypes.PromotionsState = {
  register: {
    searchGrouper: {
      name: 'searchGrouper',
      label: '',
      placeholder: 'Buscar',
      value: '',
      errors: []
    },
    name: {
      name: 'name',
      label: 'Nombre de la promoción *',
      placeholder: 'Nombre',
      value: '',
      errors: []
    },
    description: {
      name: 'description',
      label: 'Descripción *',
      placeholder: 'De que trata tu promoción',
      value: '',
      errors: []
    },
    beginDate: {
      name: 'beginDate',
      label: 'Fecha de inicio *',
      placeholder: 'DD/MM/AAAA',
      value: moment(today, 'DD/MM/YYYY'),
      errors: []
    },
    endDate: {
      name: 'endDate',
      label: 'Fecha final *',
      placeholder: 'DD/MM/AAAA',
      value: '',
      errors: []
    },
    beginHour: {
      name: 'beginHour',
      label: 'Hora de inicio *',
      placeholder: '00:00',
      value: '',
      errors: []
    },
    endHour: {
      name: 'endHour',
      label: 'Hora final *',
      placeholder: '23:59',
      value: '',
      errors: []
    },
    chooseProduct: {
      name: 'chooseProduct',
      label: 'Producto *',
      placeholder: 'Busca el producto',
      value: '',
      errors: []
    },
    chooseAdditionalProduct: {
      name: 'chooseAdditionalProduct',
      label: 'Producto *',
      placeholder: 'Busca el producto',
      value: '',
      errors: []
    },
    chooseProductQuantity: {
      name: 'chooseProductQuantity',
      label: 'Cantidad *',
      placeholder: '0',
      value: '',
      errors: []
    },
    chooseAdditionalProductQuantity: {
      name: 'chooseAdditionalProductQuantity',
      label: 'Cantidad *',
      placeholder: '0',
      value: '',
      errors: []
    },
    chooseProductPrice: {
      name: 'chooseProductPrice',
      label: 'Precio *',
      placeholder: '0',
      value: '',
      errors: []
    },
    chooseAdditionalProductPrice: {
      name: 'chooseAdditionalProductPrice',
      label: 'Precio *',
      placeholder: '0',
      value: '',
      errors: []
    },
    selectedGroupers: [],
    selectedProducts: [],
    selectedAdditionalProducts: [],
    specificDaysOfWeek: [],
    daysOfWeekOptions: 'all-week',
    hoursOfDayOptions: 'all-day',
    isActive: false
  },
  // ---- BUNDLE PROMOTION
  registerBundle: {
    baseProduct: {
      name: 'baseProduct',
      label: 'Elige el / los SKU o productos base *',
      placeholder: 'Busca el SKU o producto',
      value: '',
      errors: []
    },
    baseProductQuantity: {
      name: 'baseProductQuantity',
      label: 'Cantidad de producto *',
      placeholder: '0',
      value: '',
      errors: []
    },
    baseProductDiscountType: {
      name: 'baseProductDiscountType',
      label: 'Tipo de descuento *',
      placeholder: 'Selecciona una opción',
      value: '0',
      errors: []
    },
    baseProductValue: {
      name: 'baseProductValue',
      label: '',
      placeholder: '0',
      value: '',
      errors: []
    },
    complementProduct: {
      name: 'complementProduct',
      label: 'Elige el / los SKU o productos complemento *',
      placeholder: 'Busca el SKU o producto',
      value: '',
      errors: []
    },
    complementProductQuantity: {
      name: 'complementProductQuantity',
      label: 'Cantidad de producto *',
      placeholder: '0',
      value: '',
      errors: []
    },
    complementProductDiscountType: {
      name: 'complementProductDiscountType',
      label: 'Tipo de descuento *',
      placeholder: 'Selecciona una opción',
      value: '0',
      errors: []
    },
    complementProductValue: {
      name: 'complementProductValue',
      label: '',
      placeholder: '0',
      value: '',
      errors: []
    },
    thirdProduct: {
      name: 'thirdProduct',
      label: 'Elige el / los SKU o productos complemento *',
      placeholder: 'Busca el SKU o producto',
      value: '',
      errors: []
    },
    thirdProductQuantity: {
      name: 'thirdProductQuantity',
      label: 'Cantidad de producto *',
      placeholder: '0',
      value: '',
      errors: []
    },
    thirdProductDiscountType: {
      name: 'thirdProductDiscountType',
      label: 'Tipo de descuento *',
      placeholder: 'Selecciona una opción',
      value: '0',
      errors: []
    },
    thirdProductValue: {
      name: 'thirdProductValue',
      label: '',
      placeholder: '0',
      value: '',
      errors: []
    },
    extraLevels: [],
    additionalInformation: {
      name: 'additionalInformation',
      label:
        'Ingresa información adicional o notas importantes que se deban considerar sobre esta  promoción',
      placeholder: '',
      value: '',
      errors: []
    },
    modalCreateThirdLevel: {
      isVisible: false,
      thirdLevelName: {
        name: 'thirdLevelName',
        label: 'Nombre del nuevo nivel de dinámica de promoción',
        placeholder: 'Ingresa un nombre para el nuevo nivel',
        value: null,
        errors: []
      }
    },
    modalErrorAPIResponse: {
      isVisible: false,
      message: []
    },
    selectedBaseProducts: [],
    selectedComplementProducts: [],
    selectedThirdProducts: [],
    isDeleteThirdLevelModalVisible: false
  },
  infoPromotionModal: {
    isVisible: false
  },
  gralPromotionModal: {
    isVisible: false
  },
  modalAlert: {
    isVisible: false
  },
  // ---- BULK LOAD
  bulkLoad: {
    currentFileToUpload: null,
    modalConfirmation: {
      isVisible: false
    },
    progressBar: {
      status: 'success',
      percent: 0,
      strokeColor: '#0b4793'
    },
    progressCircle: {
      percent: 0,
      successRecords: 0,
      totalRecords: 0,
      fileName: ''
    },
    errors: {
      modal: {
        isVisible: false,
        type: 'name',
        currentPromotionName: '',
        currentErrorDetails: '',
        recordNum: null
      },
      forms: {
        promotionName: {
          name: 'promotionName',
          label: 'Nombre de la promoción',
          placeholder: 'Nombre',
          value: '',
          errors: []
        },
        sku: {
          name: 'sku',
          label: 'Buscador de productos',
          placeholder: 'Busca el SKU o producto',
          value: '',
          errors: []
        },
        mechanicQuantity: {
          name: 'mechanicQuantity',
          label: 'Cantidad',
          placeholder: '0',
          value: '',
          errors: []
        },
        mechanicPrice: {
          name: 'mechanicPrice',
          label: 'Precio',
          placeholder: '0',
          value: '',
          errors: []
        },
        location: {
          name: 'location',
          label: 'Buscador de ubicaciones',
          placeholder: 'Busca la ubicación',
          value: '',
          errors: []
        },
        beginDate: {
          name: 'beginDate',
          label: 'Fecha de inicio',
          placeholder: 'DD/MM/AAAA',
          value: moment(today, 'DD/MM/YYYY'),
          errors: []
        },
        endDate: {
          name: 'endDate',
          label: 'Fecha final',
          placeholder: 'DD/MM/AAAA',
          value: '',
          errors: []
        },
        selectedGroupers: [],
        hasMoreErrorsModal: {
          isVisible: false
        }
      }
    }
  }
}

export default initialState
