/* eslint-disable prettier/prettier */
export const  SET_CATALOG_PRODUCT_NAME = 'SET_CATALOG_PRODUCT_NAME'
export const  SET_CATALOG_PRODUCT_QUANTITY = 'SET_CATALOG_PRODUCT_QUANTITY'
export const  SET_CATALOG_PRODUCT_MEASUREMENT_UNIT = 'SET_CATALOG_PRODUCT_MEASUREMENT_UNIT'
export const  SET_CATALOG_PRODUCT_SKU = 'SET_CATALOG_PRODUCT_SKU'
export const  SET_CATALOG_PRODUCT_QUOTA = 'SET_CATALOG_PRODUCT_QUOTA'
export const  SET_CATALOG_PRODUCT_PARTNER_TYPE = 'SET_CATALOG_PRODUCT_PARTNER_TYPE'
export const  SET_CATALOG_PRODUCT_BRAND = 'SET_CATALOG_PRODUCT_BRAND'
export const  SET_CATALOG_PRODUCT_CATEGORY = 'SET_CATALOG_PRODUCT_CATEGORY'
export const  SET_CATALOG_PRODUCT_PROVIDER = 'SET_CATALOG_PRODUCT_PROVIDER'
export const  SET_CATALOG_PRODUCT_UNIT_DRIVE = 'SET_CATALOG_PRODUCT_UNIT_DRIVE'
export const  SET_CATALOG_PRODUCT_TYPE = 'SET_CATALOG_PRODUCT_TYPE'
export const  SET_CATALOG_PRODUCT_BASE_PRICE = 'SET_CATALOG_PRODUCT_BASE_PRICE'
export const  SET_CATALOG_PRODUCT_BASE_COST = 'SET_CATALOG_PRODUCT_BASE_COST'
export const  SET_CATALOG_PRODUCT_BAR_CODE = 'SET_CATALOG_PRODUCT_BAR_CODE'
export const  SET_CATALOG_PRODUCT_AVAILABILITY_LOCATION = 'SET_CATALOG_PRODUCT_AVAILABILITY_LOCATION'
export const  SET_CATALOG_PRODUCT_BAR_CODES = 'SET_CATALOG_PRODUCT_BAR_CODES'
export const  SET_CATALOG_PRODUCT_AVAILABILITIES = 'SET_CATALOG_PRODUCT_AVAILABILITIES'
export const SET_FORMAT_CATALOG_REGISTER_FORM = 'SET_FORMAT_CATALOG_REGISTER_FORM'
export const SET_CATALOG_PRODUCT_TYPEOF_PRICES = 'SET_CATALOG_PRODUCT_TYPEOF_PRICES'

// Errors
export const SET_CATALOG_PRODUCT_NAME_ERRORS = 'SET_CATALOG_PRODUCT_NAME_ERRORS'
export const SET_CATALOG_PRODUCT_QUANTITY_ERRORS = 'SET_CATALOG_PRODUCT_QUANTITY_ERRORS'
export const SET_CATALOG_PRODUCT_MEASUREMENT_UNIT_ERRORS = 'SET_CATALOG_PRODUCT_MEASUREMENT_UNIT_ERRORS'
export const SET_CATALOG_PRODUCT_SKU_ERRORS = 'SET_CATALOG_PRODUCT_SKU_ERRORS'
export const SET_CATALOG_PRODUCT_QUOTA_ERRORS = 'SET_CATALOG_PRODUCT_QUOTA_ERRORS'
export const SET_CATALOG_PRODUCT_PARTNER_TYPE_ERRORS = 'SET_CATALOG_PRODUCT_PARTNER_TYPE_ERRORS'
export const SET_CATALOG_PRODUCT_BRAND_ERRORS = 'SET_CATALOG_PRODUCT_BRAND_ERRORS'
export const SET_CATALOG_PRODUCT_CATEGORY_ERRORS = 'SET_CATALOG_PRODUCT_CATEGORY_ERRORS'
export const SET_CATALOG_PRODUCT_PROVIDER_ERRORS = 'SET_CATALOG_PRODUCT_PROVIDER_ERRORS'
export const SET_CATALOG_PRODUCT_UNIT_DRIVE_ERRORS = 'SET_CATALOG_PRODUCT_UNIT_DRIVE_ERRORS'
export const SET_CATALOG_PRODUCT_TYPE_ERRORS = 'SET_CATALOG_PRODUCT_TYPE_ERRORS'
export const SET_CATALOG_PRODUCT_BASE_PRICE_ERRORS = 'SET_CATALOG_PRODUCT_BASE_PRICE_ERRORS'
export const SET_CATALOG_PRODUCT_BASE_COST_ERRORS = 'SET_CATALOG_PRODUCT_BASE_COST_ERRORS'
export const SET_CATALOG_PRODUCT_BAR_CODE_ERRORS = 'SET_CATALOG_PRODUCT_BAR_CODE_ERRORS'
export const SET_CATALOG_PRODUCT_AVAILABILITY_LOCATION_ERRORS = 'SET_CATALOG_PRODUCT_AVAILABILITY_LOCATION_ERRORS'

// Components
export const SET_CATALOG_MODAL_EDIT_PRICE_VISIBLE = 'SET_CATALOG_MODAL_EDIT_PRICE_VISIBLE'
export const SET_CATALOG_MODAL_PRODUCT_REGISTER_VISIBLE = 'SET_CATALOG_MODAL_PRODUCT_REGISTER_VISIBLE'

// Prices by location
export const SET_CATALOG_PRODUCT_PRICE = 'SET_CATALOG_PRODUCT_PRICE'
export const SET_CATALOG_PRODUCT_PRICE_LOCATION = 'SET_CATALOG_PRODUCT_PRICE_LOCATION'
export const SET_CATALOG_PRODUCT_PRICES_LOCATIONS = 'SET_CATALOG_PRODUCT_PRICES_LOCATIONS'
export const SET_CATALOG_PRODUCT_PRICE_ERRORS = 'SET_CATALOG_PRODUCT_PRICE_ERRORS'
export const SET_CATALOG_PRODUCT_PRICE_LOCATION_ERRORS = 'SET_CATALOG_PRODUCT_PRICE_LOCATION_ERRORS'
export const SET_FORMAT_CATALOG_REGISTER_PRICES_LOCATION_FORM = 'SET_FORMAT_CATALOG_REGISTER_PRICES_LOCATION_FORM'
export const SET_CATALOG_PRODUCT_LOCATION = 'SET_CATALOG_PRODUCT_LOCATION'
export const SET_CATALOG_EDIT_SEARCH_PRICE_LOCATION = 'SET_CATALOG_EDIT_SEARCH_PRICE_LOCATION'
export const SET_CATALOG_EDIT_MODAL_VISIBLE = 'SET_CATALOG_EDIT_MODAL_VISIBLE'
export const SET_CATALOG_EDIT_INDEX = 'SET_CATALOG_EDIT_INDEX'
export const SET_CATALOG_EDIT_VALUES = 'SET_CATALOG_EDIT_VALUES'
export const SET_CATALOG_ON_CONFIRM_MODAL = 'SET_CATALOG_ON_CONFIRM_MODAL'

//Edit products
export const SET_FORMAT_CATALOG_EDIT_PRODUCT = 'SET_FORMAT_CATALOG_EDIT_PRODUCT'
export const SET_CATALOG_EDIT_PRODUCT_NAME = 'SET_CATALOG_EDIT_PRODUCT_NAME'
export const SET_CATALOG_EDIT_PRODUCT_QUANTITY = 'SET_CATALOG_EDIT_PRODUCT_QUANTITY'
export const SET_CATALOG_EDIT_PRODUCT_MEASUREMENT_UNIT = 'SET_CATALOG_EDIT_PRODUCT_MEASUREMENT_UNIT'
export const SET_CATALOG_EDIT_PRODUCT_SKU = 'SET_CATALOG_EDIT_PRODUCT_SKU'
export const SET_CATALOG_EDIT_PRODUCT_QUOTA = 'SET_CATALOG_EDIT_PRODUCT_QUOTA'
export const SET_CATALOG_EDIT_PRODUCT_PARTNER_TYPE = 'SET_CATALOG_EDIT_PRODUCT_PARTNER_TYPE'
export const SET_CATALOG_EDIT_PRODUCT_BRAND = 'SET_CATALOG_EDIT_PRODUCT_BRAND'
export const SET_CATALOG_EDIT_PRODUCT_CATEGORY = 'SET_CATALOG_EDIT_PRODUCT_CATEGORY'
export const SET_CATALOG_EDIT_PRODUCT_PROVIDER = 'SET_CATALOG_EDIT_PRODUCT_PROVIDER'
export const SET_CATALOG_EDIT_PRODUCT_UNIT_DRIVE = 'SET_CATALOG_EDIT_PRODUCT_UNIT_DRIVE'
export const SET_CATALOG_EDIT_PRODUCT_TYPE = 'SET_CATALOG_EDIT_PRODUCT_TYPE'
export const SET_CATALOG_EDIT_PRODUCT_TYPE_OF_PRICE = 'SET_CATALOG_EDIT_PRODUCT_TYPE_OF_PRICE'
export const SET_CATALOG_EDIT_PRODUCT_BASE_PRICE = 'SET_CATALOG_EDIT_PRODUCT_BASE_PRICE'
export const SET_CATALOG_EDIT_PRODUCT_BASE_COST = 'SET_CATALOG_EDIT_PRODUCT_BASE_COST'
export const SET_CATALOG_EDIT_PRODUCT_BAR_CODE = 'SET_CATALOG_EDIT_PRODUCT_BAR_CODE'
export const SET_CATALOG_EDIT_PRODUCT_BAR_CODES = 'SET_CATALOG_EDIT_PRODUCT_BAR_CODES'
export const SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATIONS = 'SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATIONS'
export const SET_CATALOG_EDIT_PRODUCT_AVAILABILITIES = 'SET_CATALOG_EDIT_PRODUCT_AVAILABILITIES'
export const SET_CATALOG_EDIT_PRODUCT_PRICE = 'SET_CATALOG_EDIT_PRODUCT_PRICE'
export const SET_CATALOG_EDIT_PRODUCT_PRICES_LOCATION = 'SET_CATALOG_EDIT_PRODUCT_PRICES_LOCATION'
export const SET_CATALOG_EDIT_PRODUCT_SEARCH_PRICE_LOCATION = 'SET_CATALOG_EDIT_PRODUCT_SEARCH_PRICE_LOCATION'
export const SET_CATALOG_EDIT_MODAL_PRODUCT_SEARCH_PRICE_LOCATION = 'SET_CATALOG_EDIT_MODAL_PRODUCT_SEARCH_PRICE_LOCATION'
export const SET_CATALOG_EDIT_PRODUCT_PRICES_LOCATIONS = 'SET_CATALOG_EDIT_PRODUCT_PRICES_LOCATIONS'
export const SET_CATALOG_EDIT_MODAL_PRODUCT_LOCATIONS = 'SET_CATALOG_EDIT_MODAL_PRODUCT_LOCATIONS'
export const SET_CATALOG_EDIT_PRODUCT_LOCATIONS = 'SET_CATALOG_EDIT_PRODUCT_LOCATIONS'
export const SET_CATALOG_EDIT_PRODUCT_EDIT_PRODUCT_PRICE_LOCATION = 'SET_CATALOG_EDIT_PRODUCT_EDIT_PRODUCT_PRICE_LOCATION'
export const SET_CATALOG_EDIT_MODAL_PRODUCT_PRICE_LOCATION = 'SET_CATALOG_EDIT_MODAL_PRODUCT_PRICE_LOCATION'
export const SET_CATALOG_EDIT_PRODUCT_ON_CONFIRM_MODAL = 'SET_CATALOG_EDIT_PRODUCT_ON_CONFIRM_MODAL'

//! EDIT ERRORS /////////////////////////////////////////

export const SET_CATALOG_EDIT_PRODUCT_NAME_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_NAME_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_QUANTITY_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_QUANTITY_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_MEASUREMENT_UNIT_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_MEASUREMENT_UNIT_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_SKU_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_SKU_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_QUOTA_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_QUOTA_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_PARTNER_TYPE_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_PARTNER_TYPE_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_BRAND_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_BRAND_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_CATEGORY_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_CATEGORY_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_PROVIDER_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_PROVIDER_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_UNIT_DRIVE_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_UNIT_DRIVE_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_TYPE_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_TYPE_ERRORS'

export const SET_CATALOG_EDIT_PRODUCT_BAR_CODES_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_BAR_CODES_ERRORS'
export const SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATION_ERRORS = 'SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATION_ERRORS'

//Product Search
export const SET_CATALOG_PRODUCT_SEARCH_BY_NAME = 'SET_CATALOG_PRODUCT_SEARCH_BY_NAME'
export const SET_CATALOG_PRODUCT_SEARCH_BY_CODE = 'SET_CATALOG_PRODUCT_SEARCH_BY_CODE'
export const SET_CATALOG_PRODUCT_SEARCH_BY_SKU = 'SET_CATALOG_PRODUCT_SEARCH_BY_SKU'
export const SET_FORMAT_CATALOG_PRODUCT_SEARCH = 'SET_FORMAT_CATALOG_PRODUCT_SEARCH'

//Disclaimer Modal
export const SET_CATALOG_DISCLAIMER_MODAL = 'SET_CATALOG_DISCLAIMER_MODAL'
export const SET_FORMAT_CATALOG_DISCLAIMER_MODAL = 'SET_FORMAT_CATALOG_DISCLAIMER_MODAL'



