export const PREFIX_KEY_MAM_APK_FILES = 'MAM/apks-front'
export const PREFIX_KEY_MAM_CSV_FILES = 'MAM/deploymentGroup-front'

export const typeOfSearchs = [
  {
    value: 'ORDER',
    title: 'ID de Orden'
  },
  {
    value: 'STORE',
    title: 'ID de Modelorama'
  },
  {
    value: 'CLIENT',
    title: 'ID de Cliente'
  }
]

export const colorStatus: { [k: string]: string } = {
  PENDING: '#FF8C07',
  PLACED: '#E02319',
  CONFIRMED: '#0B4793',
  IN_TRANSIT: '#0B4793',
  DELIVERED: '#009632',
  PENDING_CANCELLATION: '#E02319',
  CANCELLED: '#E02319',
  CLOSED: '#009632',
  INVOICED: '#009632'
}

export const typeStatusTranslated: { [k: string]: string } = {
  PENDING: 'Pendiente',
  PLACED: 'Por aceptar',
  CONFIRMED: 'Por entregar',
  IN_TRANSIT: 'En tránsito',
  DELIVERED: 'Completada',
  PENDING_CANCELLATION: 'Pendiente de cancelación',
  CANCELLED: 'Cancelada',
  CLOSED: 'Completada',
  INVOICED: 'Facturada'
}

export enum typesTranslated {
  order = 'ORDER',
  client = 'CLIENT',
  store = 'STORE'
}

export const deliveryTypes = {
  PICKUP: 'Click&Collect',
  DELIVERY: 'Delivery'
}
