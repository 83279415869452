// Packages
import fetch from 'isomorphic-fetch'
import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client'

// Assets
import config from '../data/env'
import cache from './chache'
import authLink from './authLink'
import errorLink from './errorLink'

const httpLink = new HttpLink({
  uri: `https://${config.baseServiceUri}`,
  fetch
})

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
  credentials: 'include'
})

export default client
