// Assets
import * as actionTypes from '../../actions/deliveryWindows/actionTypes'
import DeliveryWindowsTypes from '../../types/DeliveryWindowsTypes'
import FluxTypes from '../../types/FluxTypes'

const reducer: (
  state: DeliveryWindowsTypes.State,
  action: FluxTypes.ActionObject
) => DeliveryWindowsTypes.State = (state, action) => {
  let newState = state

  switch (action.type) {
    case actionTypes.SET_DELIVERY_WINDOWS_STORE_ID:
      {
        newState = {
          ...state,
          storeID: {
            ...state.storeID,
            value: action.value
          }
        }
      }
      break
    case actionTypes.SET_DELIVERY_WINDOWS_CLIENT_ID:
      {
        newState = {
          ...state,
          clientID: {
            ...state.clientID,
            value: action.value
          }
        }
      }
      break
    case actionTypes.SET_DELIVERY_WINDOWS_DELIVERY_DAYS:
      {
        newState = {
          ...state,
          deliveryDays: {
            ...state.deliveryDays,
            value: action.value
          }
        }
      }
      break
    case actionTypes.SET_DELIVERY_WINDOWS_STORE_ID_ERRORS:
      {
        newState = {
          ...state,
          storeID: {
            ...state.storeID,
            errors: action.errors
          }
        }
      }
      break
    case actionTypes.SET_DELIVERY_WINDOWS_CLIENT_ID_ERRORS:
      {
        newState = {
          ...state,
          clientID: {
            ...state.clientID,
            errors: action.errors
          }
        }
      }
      break
    case actionTypes.SET_DELIVERY_WINDOWS_DELIVERY_DAYS_ERRORS:
      {
        newState = {
          ...state,
          deliveryDays: {
            ...state.deliveryDays,
            errors: action.errors
          }
        }
      }
      break

    case actionTypes.SET_CURRENT_SELECTED_ROW:
      {
        if (action.value && state.currentSelectedRow) {
          newState = {
            ...state,
            currentSelectedRow: {
              ...state.currentSelectedRow,
              ...action.value
            }
          }
        } else {
          newState = {
            ...state,
            currentSelectedRow: action.value
          }
        }
      }
      break

    case actionTypes.SET_ORIGINAL_SELECTED_ROW:
      {
        if (action.value && state.originalSelectedRow) {
          newState = {
            ...state,
            originalSelectedRow: {
              ...state.originalSelectedRow,
              ...action.value
            }
          }
        } else {
          newState = {
            ...state,
            originalSelectedRow: action.value
          }
        }
      }
      break

    case actionTypes.SET_MODAL_EDITION_VISIBLE:
      {
        newState = {
          ...state,
          isModalEditionOpen: action.value
        }
      }
      break

    case actionTypes.SET_MODAL_CHANGE_STATUS_VISIBLE:
      {
        newState = {
          ...state,
          modalChangeStatus: {
            ...state.modalChangeStatus,
            ...action.value
          }
        }
      }
      break

    case actionTypes.SET_SELECTED_ROWS:
      {
        newState = {
          ...state,
          selectedRows: {
            ...state.selectedRows,
            rows: action.value
          }
        }
      }
      break

    case actionTypes.SET_SELECTED_ROW_KEYS:
      {
        newState = {
          ...state,
          selectedRows: {
            ...state.selectedRows,
            keys: action.value
          }
        }
      }
      break

    case actionTypes.SET_SELECTED_ROWS_MODAL_CHANGE_STATUS:
      {
        newState = {
          ...state,
          selectedRows: {
            ...state.selectedRows,
            modalChangeStatus: {
              ...state.selectedRows.modalChangeStatus,
              ...action.value
            }
          }
        }
      }
      break

    default:
      break
  }

  return newState
}

export default reducer
