// Types
import CatalogStoreTypes from '../../types/CatalogStoreTypes'

const initialState: CatalogStoreTypes.CatalogState = {
  register: {
    productName: {
      name: 'productName',
      label: 'Nombre',
      placeholder: '',
      value: '',
      errors: []
    },
    productQuantity: {
      name: 'productQuantity',
      label: 'Cantidad',
      placeholder: '',
      value: '',
      errors: []
    },
    productMeasurementUnit: {
      name: 'productMeasurementUnit',
      label: 'Unidad de medida',
      placeholder: '',
      value: '',
      errors: []
    },
    productSku: {
      name: 'productSku',
      label: 'SKU',
      placeholder: '',
      value: '',
      errors: []
    },
    productQuota: {
      name: 'productQuota',
      label: 'Cupo',
      placeholder: '',
      value: '',
      errors: []
    },
    productPartnerType: {
      name: 'productPartnerType',
      label: 'Tipo de socio',
      placeholder: '',
      value: '',
      errors: []
    },
    productBrand: {
      name: 'productBrand',
      label: 'Marca',
      placeholder: '',
      value: { name: '', id: '' },
      errors: []
    },
    productCategory: {
      name: 'productCategory',
      label: 'Categoría',
      placeholder: '',
      value: '',
      errors: []
    },
    productProvider: {
      name: 'productProvider',
      label: 'Proveedor',
      placeholder: '',
      value: { name: '', id: '' },
      errors: []
    },
    productUnitDrive: {
      name: 'productUnitDrive',
      label: 'Unidad de manejo',
      placeholder: '',
      value: '',
      errors: []
    },
    productType: {
      name: 'productType',
      label: 'Tipo de producto',
      placeholder: '',
      value: '',
      errors: []
    },
    productBasePrice: {
      name: 'productBasePrice',
      label: 'Precio base',
      placeholder: 'Ingresa el precio base (PTC)',
      value: '',
      errors: []
    },
    productBaseCost: {
      name: 'productBaseCost',
      label: 'Costo base',
      placeholder: 'Ingresa el costo base (PTR)',
      value: '',
      errors: []
    },
    productBarCode: {
      name: 'productBarCode',
      label: '',
      placeholder: 'Ingresa un código de barras',
      value: '',
      errors: []
    },
    productAvailabilityLocation: {
      name: 'productAvailabilityLocation',
      label: '',
      placeholder: 'Buscar',
      value: '',
      errors: []
    },
    productBarcodes: [],
    productAvailabilities: [],
    isProductRegisterModalVisible: false,
    isCatalogModalEditPriceVisible: false,
    onConfirmModal: false,
    productTypeofPrices: {
      buttons: [
        {
          value: 'base-price',
          children: 'Precio base'
        },
        {
          value: 'price-by-location',
          children: 'Precio por ubicación'
        }
      ],
      selectedValue: 'base-price'
    }
  },
  registerPriceByLocation: {
    productPrice: {
      name: 'productPrice',
      label: '',
      placeholder: 'Precio de producto',
      value: '',
      errors: []
    },
    productPriceLocation: {
      name: 'productPriceLocation',
      label: '',
      placeholder: 'Ubicación al que aplicará',
      value: '',
      errors: []
    },
    editSearchPriceLocation: {
      name: 'editSearchPriceLocation',
      label: '',
      placeholder: 'Ubicación al que aplicará',
      value: '',
      errors: []
    },
    productPricesLocations: [],
    productLocations: [],
    onConfirmModal: false,
    editModalVisible: false,
    editIndex: null,
    onEditingValues: {
      price: '',
      locations: []
    }
  },
  editProduct: {
    editProductName: {
      name: 'editProductName',
      label: 'Nombre',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductQuantity: {
      name: 'editProductQuantity',
      label: 'Cantidad',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductMeasurementUnit: {
      name: 'editProductMeasurementUnit',
      label: 'Unidad de medida',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductSku: {
      name: 'editProductSku',
      label: 'SKU',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductQuota: {
      name: 'editProductQuota',
      label: 'Cupo',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductPartnerType: {
      name: 'editProductPartnerType',
      label: 'Tipo de socio',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductBrand: {
      name: 'editProductBrand',
      label: 'Marca',
      placeholder: '',
      value: { name: '', id: '' },
      errors: []
    },
    editProductCategory: {
      name: 'editProductCategory',
      label: 'Categoría',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductProvider: {
      name: 'editProductProvider',
      label: 'Proveedor',
      placeholder: '',
      value: { name: '', id: '' },
      errors: []
    },
    editProductUnitDrive: {
      name: 'editProductUnitDrive',
      label: 'Unidad de manejo',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductType: {
      name: 'editProductType',
      label: 'Tipo de producto',
      placeholder: '',
      value: '',
      errors: []
    },
    editProductTypeofPrice: {
      buttons: [
        {
          value: 'base-price',
          children: 'Precio base'
        },
        {
          value: 'price-by-location',
          children: 'Precio por ubicación'
        }
      ],
      selectedValue: 'base-price'
    },
    editProductBasePrice: {
      name: 'editProductBasePrice',
      label: 'Precio base',
      placeholder: 'Ingresa el precio base (PTC)',
      value: '',
      errors: []
    },
    editProductBaseCost: {
      name: 'editProductBaseCost',
      label: 'Costo base',
      placeholder: 'Ingresa el costo base (PTR)',
      value: '',
      errors: []
    },
    editProductBarCode: {
      name: 'editProductBarCode',
      label: '',
      placeholder: 'Ingresa un código de barras',
      value: '',
      errors: []
    },
    editProductBarcodes: [],
    editProductAvailabilityLocation: {
      name: 'editProductAvailabilityLocation',
      label: '',
      placeholder: 'Buscar',
      value: '',
      errors: []
    },
    editProductAvailabilities: [],
    editProductPrice: {
      name: 'editProductPrice',
      label: '',
      placeholder: 'Precio de producto',
      value: '',
      errors: []
    },
    editProductSearchPriceLocation: {
      name: 'editProductSearchPriceLocation',
      label: '',
      placeholder: 'Ubicación al que aplicará',
      value: '',
      errors: []
    },
    editModalSearchPriceLocation: {
      name: 'editModalSearchPriceLocation',
      label: '',
      placeholder: 'Ubicación al que aplicará',
      value: '',
      errors: []
    },
    editProductPricesLocations: [],
    editProductLocations: [],
    editModalProductPriceLocation: {
      modalVisible: false,
      editIndex: null,
      value: null
    },
    editOnConfirmModal: false
  },
  productSearch: {
    searchByName: {
      name: 'searchByName',
      label: '',
      placeholder: 'Producto',
      value: '',
      errors: []
    },
    searchByCode: {
      name: 'searchByCode',
      label: '',
      placeholder: 'Código de barras',
      value: '',
      errors: []
    },
    searchBySku: {
      name: 'searchBySku',
      label: '',
      placeholder: 'SKU',
      value: '',
      errors: []
    }
  },
  disclaimerModal: {
    isVisible: false,
    title: '',
    message: '',
    errors: []
  }
}

export default initialState
