export const isBrowser: () => boolean = () =>
  typeof window !== 'undefined' ? true : false

export const haveLocalStorage: () => boolean = () =>
  'localStorage' in window ? true : false

export const getAccessToken: () => void = () =>
  isBrowser() && window.localStorage.getItem('accessToken')
    ? window.localStorage.getItem('accessToken')
    : {}

export const getUser: () => any = () =>
  isBrowser() && haveLocalStorage()
    ? JSON.parse(window.localStorage.getItem('gatsbyUser') || '{}')
    : {}

export const getMasiveUpload: () => any = () =>
  isBrowser() && window.localStorage.getItem('masiveUploadFile')
    ? window.localStorage.getItem('masiveUploadFile')
    : null

export const deleteMasiveUpload: () => void = () => {
  if (isBrowser()) {
    window.localStorage.removeItem('masiveUploadFile')
  }
}

export const setUser: (user: string) => void = (user) =>
  window.localStorage.setItem('gatsbyUser', JSON.stringify(user))

export const setIdToken: (idToken: string) => void = (idToken) =>
  window.localStorage.setItem('idToken', idToken)

export const setAccessToken: (accessToken: string) => void = (accessToken) =>
  window.localStorage.setItem('accessToken', accessToken)

export const setRefreshToken: (refreshToken: string) => void = (refreshToken) =>
  window.localStorage.setItem('refreshToken', refreshToken)

export const setParametersUser: (data: any) => void = (data) => {
  setIdToken(data.IdToken)
  setAccessToken(data.accessToken)
  setRefreshToken(data.refreshToken)
  setUser(data)
}

export const deleteParametersUser: () => void = () => {
  if (isBrowser()) {
    window.localStorage.removeItem('idToken')
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('refreshToken')
    window.localStorage.removeItem('gatsbyUser')
    window.localStorage.removeItem('temporaryUser')
  }
}

export const isLoggedIn: () => any = () => {
  const user = getUser()
  return !!user.accessToken
}
