// Packages
import _ from 'lodash'

// Assets
import baseConfig from './base.json'
import developmentConfig from './development.json'
import qaConfig from './qa.json'
import productionConfig from './production.json'
import stagingConfig from './staging.json'

const configs: { [x: string]: { [x: string]: any } } = {
  development: _.merge({}, baseConfig, developmentConfig),
  qa: _.merge({}, baseConfig, developmentConfig, qaConfig),
  staging: _.merge({}, baseConfig, developmentConfig, qaConfig, stagingConfig),
  production: _.merge(
    {},
    baseConfig,
    developmentConfig,
    qaConfig,
    stagingConfig,
    productionConfig
  )
}

const environment = process.env.GATSBY_ACTIVE_ENV || 'development'

const config = configs[environment]

// Enviroment variables
config.baseServiceUri = process.env.GATSBY_URI_ADMIN_SERVICE
config.amplifyRegion = process.env.GATSBY_AMPLIFY_REGION
config.amplifyUserPoolId = process.env.GATSBY_AMPLIFY_USER_POOL_ID
config.amplifyUserPoolWebClientId =
  process.env.GATSBY_AMPLIFY_USER_POOL_WEB_CLIENT_ID
config.wsLink = process.env.GATSBY_URI_WS_ADMIN
config.googleMaps.apiKey = process.env.GATSBY_MAPS_KEY

export default config
