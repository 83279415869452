// Types
import { RadioProps, CheckboxOptionType } from 'antd'

export const daysOfWeekOptions: RadioProps[] = [
  {
    value: 'all-week',
    children: 'Todos lo días de la semana'
  },
  {
    value: 'specific-days',
    children: 'Días específicos de la semana'
  }
]

export const daysOfWeekCheckboxes: CheckboxOptionType[] = [
  {
    label: 'Domingo',
    value: 'SUN'
  },
  {
    label: 'Jueves',
    value: 'THU'
  },
  {
    label: 'Lunes',
    value: 'MON'
  },
  {
    label: 'Viernes',
    value: 'FRI'
  },
  {
    label: 'Martes',
    value: 'TUE'
  },
  {
    label: 'Sábado',
    value: 'SAT'
  },
  {
    label: 'Miércoles',
    value: 'WED'
  }
]

export const hoursOfDayOptions: RadioProps[] = [
  {
    value: 'all-day',
    children: 'Todo el día'
  },
  {
    value: 'specific-hour',
    children: 'Horario específico'
  }
]
