// Packages
import React, { useReducer, useMemo } from 'react'

// Types
import FluxTypes from '../../types/FluxTypes'

// Reducers
import promotionsReducer from '../../stores/promotionsStore/reducer'
import appReducer from '../../stores/appStore/reducer'
import catalogReducer from '../../stores/catalogStore/reducer'
import catalogMassiveUploadReducer from '../../stores/catalogMassiveUpload/reducer'
import deliveryWindowsReducer from '../../stores/deliveryWindows/reducer'

// States
import promotionsInitialState from '../../stores/promotionsStore/initialState'
import appStoreInitialState from '../../stores/appStore/initialState'
import catalogInitialState from '../../stores/catalogStore/initialState'
import catalogMassiveUploadState from '../../stores/catalogMassiveUpload/initialState'
import deliveryWindowsState from '../../stores/deliveryWindows/initialState'

// Context
import appContext from '../../contexts/appContext'

const combineReducers =
  (slices: { [x: string]: any }) =>
  (state: FluxTypes.State, action: FluxTypes.Action) => {
    return Object.keys(slices).reduce(
      (acc, prop) => ({
        ...acc,
        [prop]: slices[prop](acc[prop], action)
      }),
      state
    )
  }

const rootReducer = combineReducers({
  promotions: promotionsReducer,
  app: appReducer,
  catalog: catalogReducer,
  catalogMassiveUpload: catalogMassiveUploadReducer,
  deliveryWindows: deliveryWindowsReducer
})

const initialState = {
  promotions: {
    ...promotionsInitialState
  },
  app: {
    ...appStoreInitialState
  },
  catalog: {
    ...catalogInitialState
  },
  catalogMassiveUpload: {
    ...catalogMassiveUploadState
  },
  deliveryWindows: {
    ...deliveryWindowsState
  }
}

interface Props {
  addToGlobalDispatchers: (
    dispatch: React.Dispatch<FluxTypes.ActionObject>,
    name: string
  ) => void
  addToGlobalState: (state: FluxTypes.State, name: string) => void
  name: string
}

const WithFluxStoreContextProvider: React.FC<Props> = ({
  addToGlobalDispatchers,
  addToGlobalState,
  children,
  name
}) => {
  const [state, dispatch] = useReducer(rootReducer, initialState)

  addToGlobalDispatchers(dispatch, name)
  addToGlobalState(state, name)

  const store = useMemo(() => [state, dispatch], [state])

  return <appContext.Provider value={store}>{children}</appContext.Provider>
}

export default WithFluxStoreContextProvider
