// Packages
import { InMemoryCache } from '@apollo/client'

//const cache = new InMemoryCache()
const cache: InMemoryCache = new InMemoryCache({
  dataIdFromObject: () => false,
  addTypename: false
})

export default cache
