// Types
export enum Status {
  disabled = 'disabled',
  inactive = 'inactive',
  active = 'active',
  pendingReplication = 'pendingReplication',
  error = 'error',
  scheduled = 'scheduled',
  pendingToStartProcess = 'pendingToStartProcess'
}

const promotionsStatus = [
  { key: Status.disabled, label: 'Estatus' },
  { key: Status.inactive, label: 'Inactiva' },
  { key: Status.active, label: 'Activa' },
  {
    key: Status.pendingReplication,
    label: 'Pendiente de replicación'
  },
  { key: Status.error, label: 'Error' },
  { key: Status.scheduled, label: 'Programada' },
  { key: Status.pendingToStartProcess, label: 'En espera de procesarse' }
]

export default promotionsStatus
