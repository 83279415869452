import React from 'react'

import { GET_USER_ROLES } from '../../graphql/auth'
import { useQuery } from '@apollo/client'

// interface Roles {
//   id: string
//   writeAllowed: boolean
// }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUserRoles = (user: any) => {
  const { data, loading, refetch } = useQuery(GET_USER_ROLES, {
    variables: {
      username: user?.attributes.sub
    },
    skip: !user
  })

  React.useEffect(() => {
    if (!user) return
    refetch()
  }, [user])

  return {
    userRoles: data?.getUserRole?.allowedModules || [],
    loading,
    refetch
  }
}

export default useUserRoles
