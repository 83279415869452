// Assets
import * as actionTypes from '../../actions/app/actionTypes'

// Types
import AppStoreTypes from '../../types/AppStoreTypes'
import FluxTypes from '../../types/FluxTypes'

const reducer: (
  state: AppStoreTypes.AppState,
  action: FluxTypes.ActionObject
) => AppStoreTypes.AppState = (state, action) => {
  let newState = state

  switch (action.type) {
    case actionTypes.SET_PROMOTIONS_ALERT_VISIBLE: {
      newState = {
        ...state,
        alert: {
          ...state.alert,
          isVisible: action.value
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_ALERT_TYPE: {
      newState = {
        ...state,
        alert: {
          ...state.alert,
          type: action.value
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_ALERT_MESSAGES: {
      newState = {
        ...state,
        alert: {
          ...state.alert,
          messages: action.value
        }
      }
      break
    }

    case actionTypes.SET_ALERT: {
      newState = {
        ...state,
        alert: {
          ...state.alert,
          type: action.value.type,
          messages: action.value.messages,
          isVisible: action.value.isVisible
        }
      }
      break
    }
    default:
      return newState
  }

  return newState
}

export default reducer
