import { gql } from '@apollo/client'

export const GET_USER_ROLES = gql`
  query getUserRole($username: String!) {
    getUserRole(username: $username) {
      roles {
        id
        roleName
      }
      allowedModules {
        id
        writeAllowed
      }
    }
  }
`
