// Packages
import { merge } from 'lodash'

// Assets
import * as actionPromotionsCreators from './promotions/actionCreators'
import * as actionAppCreators from './app/actionsCreators'
import * as actionCatalogCreators from './catalog/actionCreators'
import * as actionCustomerCreators from './customer/actionsCreators'
import * as actionCatalogMassiveUpload from './catalogMassiveUpload/actionsCreators'
import * as actionDeliveryWindows from './deliveryWindows/actionsCreators'

export default merge(
  actionPromotionsCreators,
  actionAppCreators,
  actionCatalogCreators,
  actionCustomerCreators,
  actionCatalogMassiveUpload,
  actionDeliveryWindows
)
