// Packages
import { ApolloLink } from '@apollo/client'

// Assets
import { getAccessToken } from '../../utils/localStorage'

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = getAccessToken() ? `Bearer ${getAccessToken()}` : ''

  operation.setContext(({ headers }: { headers: Headers }) => ({
    headers: {
      ...headers,
      authorization: accessToken
    }
  }))

  return forward(operation)
})

export default authLink
