// Assets
import * as actionTypes from '../../actions/catalogMassiveUpload/actionTypes'

// Types
import CatalogMassiveUploadTypes from '../../types/CatalogMassiveUploadTypes'
import FluxTypes from '../../types/FluxTypes'

const reducer: (
  state: CatalogMassiveUploadTypes.State,
  action: FluxTypes.ActionObject
) => CatalogMassiveUploadTypes.State = (state, action) => {
  let newState = state

  switch (action.type) {
    case actionTypes.SET_CATALOG_MASSIVE_UPLOAD_TYPE:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            type: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_MASSIVE_UPLOAD_NAME:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            name: {
              ...state.register.name,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_MASSIVE_UPLOAD_START_BUTTON:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            isStartButtonEnable: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_MASSIVE_UPLOAD_SEARCH_PRODUCT_INPUT:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            searchProductInput: {
              ...state.register.searchProductInput,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_MASSIVE_UPLOAD_MODAL_CONFIRMATION:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            isModalConfirmationVisible: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_MASSIVE_UPLOAD_PRODUCTS_TO_UPLOAD:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productsToUpload: action.value
          }
        }
      }
      break

    default:
      return newState
  }

  return newState
}

export default reducer
