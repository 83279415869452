// Assets
import * as actionTypes from '../actionTypes'
import { setCatalogModalEditPriceVisible } from '../../catalog/actionCreators'

// Types
import FluxTypes from '../../../types/FluxTypes'

// Promotions register value
export const setPromotionId: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTION_ID,
  value
})

export const setSelectedGroupers: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_SELECTED_GROUPERS,
  value
})

export const setSelectedProducts: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_SELECTED_PRODUCTS,
  value
})

export const setSelectedAdditionalProducts: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_SELECTED_PRODUCTS,
  value
})

export const setFormatPromotionRegisterForm: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_FORMAT_PROMOTION_REGISTER_FORM
})

export const setPromotionsSearchGrouper: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_SEARCH_GROUPER,
  value
})

export const setPromotionsName: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_NAME,
  value
})

export const setPromotionsDescription: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_DESCRIPTION,
  value
})

export const setPromotionsBeginDate: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_BEGIN_DATE,
  value
})

export const setPromotionsEndDate: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_END_DATE,
  value
})

export const setPromotionsChooseProduct: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT,
  value
})

export const setPromotionsChooseProductQuantity: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY,
  value
})

export const setPromotionsChooseProductPrice: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE,
  value
})

export const setPromotionsChooseAdditionalProduct: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT,
  value
})

export const setPromotionsChooseAdditionalProductQuantity: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY,
    value
  })

export const setPromotionsChooseAdditionalProductPrice: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE,
    value
  })

export const setPromotionsBeginHour: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_BEGIN_HOUR,
  value
})
export const setPromotionsEndHour: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_END_HOUR,
  value
})

export const setPromotionsDaysOfWeekOptions: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_DAYS_OF_WEEK_OPTIONS,
  value
})
export const setPromotionsHoursOfDaysOptions: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_HOURS_OF_DAY_OPTIONS,
  value
})
export const setPromotionsSpecificDaysOfWeek: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_SPECIFIC_DAYS_OF_WEEK,
  value
})

export const setPromotionsIsActive: FluxTypes.ActionCreator = (
  value: boolean
) => ({
  type: actionTypes.SET_IS_ACTIVE_PROMOTION,
  value
})

// Promotions register errors

export const setPromotionsBeginHourErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_PROMOTIONS_BEGIN_HOUR_ERRORS,
  errors
})
export const setPromotionsEndHourErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_PROMOTIONS_END_HOUR_ERRORS,
  errors
})

export const setPromotionsSearchGrouperErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_PROMOTIONS_SEARCH_GROUPER_ERRORS,
  errors
})

export const setPromotionsNameErrors: FluxTypes.ActionCreator = (errors) => ({
  type: actionTypes.SET_PROMOTIONS_NAME_ERRORS,
  errors
})

export const setPromotionsDescriptionErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_PROMOTIONS_DESCRIPTION_ERRORS,
  errors
})

export const setPromotionsBeginDateErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_PROMOTIONS_BEGIN_DATE_ERRORS,
  errors
})

export const setPromotionsEndDateErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_PROMOTIONS_END_DATE_ERRORS,
  errors
})

export const setPromotionsChooseProductErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_ERRORS,
  errors
})

export const setPromotionsChooseProductQuantityErrors: FluxTypes.ActionCreator =
  (errors) => ({
    type: actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY_ERRORS,
    errors
  })

export const setPromotionsChooseProductPriceErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE_ERRORS,
  errors
})

export const setInfoPromotionModalVisible: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_INFO_PROMOTION_MODAL_VISIBLE,
  value
})

export const setGralInfoPromotionModalVisible: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_GRAL_INFO_PROMOTION_MODAL_VISIBLE,
  value
})

export const setModalAlertVisible: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_VISIBLE_MODAL_ALERT,
  value
})

export const hideAllModals: FluxTypes.ActionCreator = () => (dispatch) => {
  dispatch(setInfoPromotionModalVisible(false))
  dispatch(setCatalogModalEditPriceVisible(false))
  dispatch(setGralInfoPromotionModalVisible(false))
}

/*
 * * BUNDLE PROMOTIONS
 */

export const setBundlePromotionsBaseProduct: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_BASE_PRODUCT,
  value
})

export const setBundlePromotionsBaseProductQuantity: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_QUANTITY,
  value
})

export const setBundlePromotionsBaseProductDiscountType: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_DISCOUNT_TYPE,
    value
  })

export const setBundlePromotionsBaseProductValue: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_VALUE,
  value
})

export const setBundlePromotionsComplementProduct: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT,
  value
})

export const setBundlePromotionsComplementProductQuantity: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_QUANTITY,
    value
  })

export const setBundlePromotionsComplementProductDiscountType: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_DISCOUNT_TYPE,
    value
  })

export const setBundlePromotionsComplementProductValue: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_VALUE,
    value
  })

export const setBundlePromotionsThirdProduct: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT,
  value
})

export const setBundlePromotionsThirdProductQuantity: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_QUANTITY,
    value
  })

export const setBundlePromotionsThirdProductDiscountType: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_DISCOUNT_TYPE,
    value
  })

export const setBundlePromotionsThirdProductValue: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_VALUE,
  value
})

export const setBundlePromotionsExtraLevelProduct: FluxTypes.ActionCreator = (
  payload
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT,
  payload
})

export const setBundlePromotionsExtraLevelProductDelete: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_DELETE,
    value
  })

export const setBundlePromotionsExtraLevelProductQuantity: FluxTypes.ActionCreator =
  (payload) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_QUANTITY,
    payload
  })

export const setBundlePromotionsExtraLevelProductDiscountType: FluxTypes.ActionCreator =
  (payload) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_DISCOUNT_TYPE,
    payload
  })

export const setBundlePromotionsExtraLevelProductValue: FluxTypes.ActionCreator =
  (payload) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_VALUE,
    payload
  })

export const setBundlePromotionsSelectedExtraLevelProducts: FluxTypes.ActionCreator =
  (payload) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_SELECTED_EXTRA_LEVEL_PRODUCTS,
    payload
  })

export const setBundlePromotionsAdditionalInformation: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_ADITIONAL_INFORMATION,
    value
  })

export const setBundlePromotionsModalCreateThirdLevelVisibility: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_MODAL_CREATE_THIRD_LEVEL_VISIBILITY,
    value
  })

export const setBundlePromotionsThirdLevelName: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_LEVEL_NAME,
  value
})

export const setBundlePromotionsExtraLevel: FluxTypes.ActionCreator = (
  payload
) => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL,
  payload
})

export const setBundlePromotionsModalAPIResponseVisibility: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_MODAL_API_RESPONSE_VISIBILITY,
    value
  })

export const setBundlePromotionsModalAPIResponseMessage: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_MODAL_API_RESPONSE_MESSAGE,
    value
  })

export const setBundlePromotionsSelectedBaseProducts: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_SELECTED_BASE_PRODUCTS,
    value
  })

export const setBundlePromotionsSelectedComplementProducts: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_SELECTED_COMPLEMENT_PRODUCTS,
    value
  })

export const setBundlePromotionsSelectedThirdProducts: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_SELECTED_THIRD_PRODUCTS,
    value
  })

export const setBundlePromotionsModalDeleteThirdLevelVisibility: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_MODAL_DELETE_THIRD_LEVEL_VISIBILITY,
    value
  })

export const setBundlePromotionsFormat: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_BUNDLE_PROMOTIONS_FORMAT
})

export const setBundlePromotionsThirdLevelFormat: FluxTypes.ActionCreator =
  () => ({
    type: actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_LEVEL_FORMAT
  })

// ---- BULK LOAD
export const setBulkLoadCurrentFile: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_BULKLOAD_CURRENT_FILE,
  value
})

export const setBulkLoadModalConfirmationVisibility: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_MODAL_CONFIRMATION_VISIBILITY,
  value
})

export const setBulkLoadProgressBar: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_BULKLOAD_PROGRESS_BAR,
  value
})

export const setBulkLoadErrorsModalVisibility: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_MODAL_VISIBILITY,
  value
})

export const setBulkLoadErrorsModalType: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_MODAL_TYPE,
  value
})

export const setBulkLoadErrorsModalPromotionName: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_MODAL_PROMOTION_NAME,
  value
})

export const setBulkLoadErrorsModalErrorDetails: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_MODAL_ERROR_DETAILS,
  value
})

export const setBulkLoadErrorsModalRecordNum: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_MODAL_RECORD_NUM,
  value
})

export const setBulkLoadErrorsModalClose: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_MODAL_CLOSE
})

export const setBulkLoadProgressCircle: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_BULKLOAD_PROGRESS_CIRCLE,
  value
})

// ---- BULK LOAD [forms]

export const setBulkLoadErrorsFormPromotionName: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_PROMOTION_NAME,
  value
})

export const setBulkLoadErrorsFormSku: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_SKU,
  value
})

export const setBulkLoadErrorsFormMechanicQuantity: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_MECHANIC_QUANTITY,
  value
})

export const setBulkLoadErrorsFormMechanicPrice: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_MECHANIC_PRICE,
  value
})

export const setBulkLoadErrorsFormLocation: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_LOCATION,
  value
})

export const setBulkLoadErrorsFormBeginDate: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_BEGIN_DATE,
  value
})

export const setBulkLoadErrorsFormEndDate: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_END_DATE,
  value
})

export const setBulkLoadErrorsFormSelectedGroupers: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_SELECTED_GROUPERS,
  value
})

export const setBulkLoadErrorsFormMoreErrorsModalVisibility: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BULKLOAD_ERRORS_FORM_MORE_ERRORS_MODAL_VISIBILITY,
    value
  })

// ---- BULK LOAD [forms errors]

export const setBulkLoadErrorsFormPromotionNameErrors: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BULKLOAD_ERRORS_FORM_PROMOTION_NAME_ERRORS,
    value
  })

export const setBulkLoadErrorsFormSkuErrors: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_SKU_ERRORS,
  value
})

export const setBulkLoadErrorsFormMechanicQuantityErrors: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BULKLOAD_ERRORS_FORM_MECHANIC_QUANTITY_ERRORS,
    value
  })

export const setBulkLoadErrorsFormMechanicPriceErrors: FluxTypes.ActionCreator =
  (value) => ({
    type: actionTypes.SET_BULKLOAD_ERRORS_FORM_MECHANIC_PRICE_ERRORS,
    value
  })

export const setBulkLoadErrorsFormLocationErrors: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_LOCATION_ERRORS,
  value
})

export const setBulkLoadErrorsFormBeginDateErrors: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_BEGIN_DATE_ERRORS,
  value
})

export const setBulkLoadErrorsFormEndDateErrors: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_BULKLOAD_ERRORS_FORM_END_DATE_ERRORS,
  value
})
