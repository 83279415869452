const promotionsFilters = {
  altSvgDownloadActive: 'download active',
  altSvgDownloadInactive: 'download inactive',
  altSvgRefresh: 'refresh icon',
  dateFormatShort: 'DD-MM-YY',
  dateFormarLong: 'YYYY-MM-DD HH:mm',
  errorDownload: 'La descarga no se pudo iniciar',
  tooltipDownload: 'Descargar búsqueda',
  tooltipRefresh: 'Limpiar filtros'
}

export default promotionsFilters
