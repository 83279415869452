// Assets
import DeliveryWindowsTypes from '../../types/DeliveryWindowsTypes'

const initialState: DeliveryWindowsTypes.State = {
  storeID: {
    name: 'storeID',
    label: '',
    placeholder: 'Modelorama ID',
    value: '',
    errors: []
  },
  clientID: {
    name: 'clientID',
    label: '',
    placeholder: 'Cliente ID',
    value: '',
    errors: []
  },
  deliveryDays: {
    name: 'deliveryDays',
    label: '',
    placeholder: 'Días de entrega',
    value: '',
    errors: []
  },
  currentSelectedRow: null,
  originalSelectedRow: null,
  isModalEditionOpen: false,
  modalChangeStatus: {
    isOpen: false
  },
  selectedRows: {
    keys: [],
    rows: [],
    modalChangeStatus: {
      isOpen: false,
      type: 'Active'
    }
  }
}

export default initialState
