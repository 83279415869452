// Assets
import * as actionTypes from '../actionTypes'

// Types
import FluxTypes from '../../../types/FluxTypes'
import AppStoreTypes from '../../../types/AppStoreTypes'

export const setAlertVisible: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_ALERT_VISIBLE,
  value
})

export const setAlertType: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_ALERT_TYPE,
  value
})

export const setAlertMessages: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_PROMOTIONS_ALERT_MESSAGES,
  value
})

export const setAlert = (value: AppStoreTypes.PromotionsAlertState) => ({
  type: actionTypes.SET_ALERT,
  value
})
