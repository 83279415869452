import { FormInstance } from 'antd'

// Filters
export const emailFilter = (value: string): string => {
  return value.replace(/[^0-9a-z._%@+-]/i, '').substr(0, 60)
}

export const onlyDigitsFilter: (
  value: string,
  options?: { isStringOfNumbers?: boolean; maxLength?: number }
) => string = (value, options): string => {
  const regNumber = options?.isStringOfNumbers ? /^\d+$/ : /^\+?[1-9][0-9]*$/
  if (regNumber.test(value)) {
    return value?.substr(0, (options && options.maxLength) || 60)
  }
  return ''
}

export const standardStringFilter = (
  value: string,
  options?: { maxLength: number }
): string => {
  return options && options.maxLength
    ? value
        .replace(/^\s+/, '')
        .replace(/\s+$/, ' ')
        .replace(
          /[^ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]/i,
          ''
        )
        .substr(0, options.maxLength)
    : value
        .replace(/^\s+/, '')
        .replace(/\s+$/, ' ')
        .replace(
          /[^ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]/i,
          ''
        )
}

// Validators
export const emailValidator = (value: string): string | boolean => {
  return value === '' || /^[0-9a-z._%+-]+@[0-9a-z.-]+\.[a-z]{2,}$/i.test(value)
}

export const maxLengthValidator = (
  value: string,
  options?: { maxLength: number }
): string | boolean => {
  return value === '' || value.length <= ((options && options.maxLength) || 60)
}

export const minLengthValidator = (
  value: string,
  options?: { minLength: number }
): string | boolean => {
  return value === '' || value.length >= ((options && options.minLength) || 2)
}

export const onlyDigitsValidator = (value: string): string | boolean => {
  return value === '' || /^[0-9]+$/.test(value)
}

export const requiredValidator = (
  value?: string | boolean
): boolean | string | undefined => {
  return typeof value === 'string' ? value !== '' : value
}

export const arrayNotEmptyValidator = (value: any[]): boolean => {
  if (Array.isArray(value)) {
    return value.length > 0
  } else {
    return false
  }
}

export const standardStringValidator = (value: string): string | boolean => {
  return (
    value === '' ||
    /^[ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]+$/i.test(
      value
    )
  )
}

export const validateInputValue = (
  value: string | any[],
  validations: any[]
): string[] => {
  return validations.reduce(
    (errorMessages, { validator, options, errorMessage }) => {
      if (!validator(value, options)) {
        errorMessages.push(errorMessage)
      }
      return errorMessages
    },
    []
  )
}

export const getUppperTargetValue = (
  e: React.ChangeEvent<HTMLInputElement>
): string => (e.target.value = ('' + e.target.value).toUpperCase())

export const formContainError = (form: FormInstance): boolean =>
  !form.isFieldsTouched(true) ||
  !!form.getFieldsError().filter(({ errors }) => errors.length).length
