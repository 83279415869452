// Assets
import promotionsCategory from './promotionsCategory'
import promotionsStatus from './promotionsStatus'
import promotionsFilters from './promotionsFilters'
import amplifyCognitoErrorMessages from './amplifyCognitoErrorMessages'
import catalogFormConstants from './catalogForm'
import typeofGroupers from './typeofGroupers'
import * as promotionsPeriocity from './promotionsPeriocity'
import disclaimerIcons from './disclaimerIcons'
import minLengthSearch from './searchInput'
import partnerErrorMessages from './partnerErrorMessages'
import store from './storeRegisterEdition'
import * as mamConstants from './mam'
import * as mawConstants from './maw'
import * as deliveryWindowsConstans from './deliveryWindows'
import * as promotionsBulkLoadConstans from './promotionsBulkLoad'
import * as wsConstans from './webSocket'
import * as promotionsBundle from './promotionsBundle'

export default {
  amplifyCognitoErrorMessages,
  catalogFormConstants,
  typeofGroupers,
  disclaimerIcons,
  minLengthSearch,
  partnerErrorMessages,
  mamConstants,
  mawConstants,
  deliveryWindowsConstans,
  promotionsBulkLoadConstans,
  promotionsBundle,
  promotionsCategory,
  promotionsFilters,
  promotionsPeriocity,
  promotionsStatus,
  wsConstans,
  store
}
