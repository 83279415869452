const amplifyCognitoErrorMessages = [
  { en: 'Invalid Refresh Token', es: 'El Token es inválido.' },
  { en: 'Refresh Token has been revoked', es: 'El Token es inválido.' },
  {
    en: 'Incorrect username or password.',
    es: 'El usuario y/o la contraseña es incorrecta.'
  },
  {
    en: 'Invalid verification code provided, please try again.',
    es: 'El código de verificación y/o el correo electrónico es incorrecto, intentalo más tarde'
  },
  {
    en: 'Invalid code provided, please request a code again.',
    es: 'El código de verificación y/o el correo electrónico es incorrecto, intentalo más tarde'
  },
  {
    en: 'Attempt limit exceeded, please try after some time.',
    es: 'Se superó el límite de intentos. Inténtelo más tarde.'
  },
  {
    en: "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6",
    es: 'La contraseña no cumple con los requisitos mínimos.'
  },
  {
    en: 'Password does not conform to policy: Password must have numeric characters',
    es: 'La contraseña no cumple con los requisitos mínimos.'
  },
  {
    en: 'Password does not conform to policy: Password not long enough',
    es: 'La contraseña no cumple con los requisitos mínimos.'
  },
  {
    en: 'Password does not conform to policy: Password must have symbol characters',
    es: 'La contraseña no cumple con los requisitos mínimos.'
  },
  {
    en: 'User already exists',
    es: 'El número de teléfono ya se encuentra registrado.'
  }
]

export default amplifyCognitoErrorMessages
