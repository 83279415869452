//Packages
import {
  signIn as signInAmplify,
  resetPassword,
  confirmResetPassword,
  confirmSignIn
} from 'aws-amplify/auth'
import moment from 'moment'

// Assets
import constants from '../../data/constants'
import GeneralTypes from '../../types/GeneralTypes'

export const formmater = (value: string | number | undefined): any =>
  `$ ${value}`.replace(/\d(?=(\d{3})+(\.|$))/g, '$&,')

export const formatCurrency: (amount: number) => string = (amount) => {
  return `$ ${formatNumber(amount, 2)}`
}

export const formatNumber: (amount: number, decimalCount?: number) => string = (
  amount,
  decimalCount = 0
) => {
  return amount.toFixed(decimalCount).replace(/\d(?=(\d{3})+(\.|$))/g, '$&,')
}

export const removeAccents: (word: string) => string = (word) => {
  return word
    .replace(/[áàãâä]/gi, 'a')
    .replace(/[éè¨ê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[úùüû]/gi, 'u')
    .replace(/[ç]/gi, 'c')
    .replace(/[ñ]/gi, 'n')
    .replace(/[^a-zA-Z0-9]/g, ' ')
}

export const formatDate: (
  date: Date,
  withMonthsInLetters?: boolean
) => string = (date, withMonthsInLetters) => {
  const originalDate = new Date(date)
  const dd = String(originalDate.getDate()).padStart(2, '0')
  const mm = String(originalDate.getMonth() + 1).padStart(2, '0')
  const yyyy = originalDate.getFullYear()
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
  if (withMonthsInLetters) {
    return dd + ' / ' + months[originalDate.getMonth()] + ' / ' + yyyy
  }

  return dd + '-' + mm + '-' + yyyy
}

export const formatGender: any = (data: any) => {
  return data?.map((item: any) => {
    return {
      value: item.genderID,
      description: item.description
    }
  })
}

export const formatNationality: any = (data: any) => {
  return data?.map((item: any) => {
    return {
      value: item.nationalityID,
      description: item.description
    }
  })
}

export const formatRolePartner: any = (data: any) => {
  return data?.map((item: any) => {
    return {
      value: item.roleID,
      description: item.description
    }
  })
}

export const selectDataGeneral: any = (
  name: string,
  gender: any,
  nationality: any,
  partnerRole: any
) => {
  if (name === 'gender') {
    return gender
  }
  if (name === 'nationality') {
    return nationality
  }
  if (name === 'role') {
    return partnerRole
  }
}

export const selectLoading: any = (
  name: string,
  gender: boolean,
  nationality: boolean,
  partnerRole: boolean
) => {
  if (name === 'gender') {
    return gender
  }
  if (name === 'nationality') {
    return nationality
  }
  if (name === 'role') {
    return partnerRole
  }
}

export const selectDataUbication: any = (
  name: string,
  neighborhoods: any,
  state: any,
  municipality: any
) => {
  if (name === 'neighborhood') {
    return neighborhoods
  }
  if (name === 'state') {
    return state
  }
  if (name === 'municipality') {
    return municipality
  }
}

export const locationSearch: any = () => {
  return window?.location.search
}

export const translateAmplifyCognitoErrorResponse: (
  errorResponse: string
) => string = (errorResponse) => {
  const { amplifyCognitoErrorMessages } = constants
  const errorTranslated = amplifyCognitoErrorMessages.filter(
    ({ en }) => en === errorResponse
  )
  return errorTranslated.length ? errorTranslated[0].es : errorResponse
}

export const isFloat: (n: number) => boolean = (n: number) => {
  return Number(n) === n && n % 1 !== 0
}

export const signIn: (
  username: string,
  password: string,
  successCallback: (succes: any) => void,
  errorCallback: (error: any) => void
) => void = (username, password, successCallback, errorCallback) => {
  signInAmplify({ username, password })
    .then((user) => {
      successCallback(user)
    })
    .catch((err) => {
      errorCallback(err)
    })
}

export const forgotPassword: (
  username: string,
  successCallback: (username: string) => void,
  errorCallback: (error: any) => void
) => void = (username, successCallback, errorCallback) => {
  resetPassword({ username })
    .then(() => successCallback(username))
    .catch((err) => errorCallback(err))
}

export const forgotPasswordSubmit: (
  user: {
    userName: string
    code: string
    password: string
  },
  successCallback: () => void,
  errorCallback: (error: any) => void
) => void = (user, successCallback, errorCallback) => {
  const { userName, code, password } = user
  confirmResetPassword({
    username: userName,
    newPassword: password,
    confirmationCode: code
  })
    .then(() => successCallback())
    .catch((err) => errorCallback(err))
}

export const updatePasswordSubmit: (
  password: string,
  successCallback: () => void,
  errorCallback: (error: any) => void
) => void = (password, successCallback, errorCallback) => {
  confirmSignIn({ challengeResponse: password })
    .then(() => successCallback())
    .catch((err) => errorCallback(err))
}

export const translateDay = (day: string): string => {
  const days: { [x: string]: string } = {
    MON: 'Lunes',
    TUE: 'Martes',
    WED: 'Miércoles',
    THU: 'Jueves',
    FRI: 'Viernes',
    SAT: 'Sábado',
    SUN: 'Domingo'
  }

  return days[day]
}

export const checkRules = (value: string): any => {
  return {
    ['Password']: {
      min: value.length >= 8,
      max: value.length <= 100,
      uppercase: new RegExp('[A-Z]').test(value),
      lowercase: new RegExp('[a-z]').test(value),
      digits: new RegExp('[0-9]').test(value),
      caracter: new RegExp('[!-/:-?[-_¿¡¨´{}]').test(value)
    }
  }
}

export const sortDaysOfWeek = (daysOfWeek: string[]): string[] => {
  const daysOrder = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  return daysOrder.filter((d) => {
    return daysOfWeek.indexOf(d) >= 0
  })
}

export const translatePartnerErrorResponse: (
  errorResponse: string
) => string = (errorResponse) => {
  const formatted = errorResponse.replaceAll('Error:', '').trim()
  const { partnerErrorMessages } = constants
  const errorTranslated = partnerErrorMessages.find(
    ({ en }) => en === formatted
  )
  return errorTranslated ? errorTranslated.es : errorResponse
}

export const waitFor = (delay: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, delay))

export const convertToDate = (unixTime: number): string => {
  const unixTimestamp = unixTime

  const milliseconds = unixTimestamp * 1

  const dateObject = new Date(milliseconds)

  const humanDateFormat = dateObject.toLocaleString()

  return humanDateFormat
}

export const formatToTableDate = (date: number): string => {
  const formattedDate = moment(moment.unix(date / 1000)).format('D-MMM-YYYY')
  return formattedDate
}

export const formatDateToTableQuery = (date: string): string => {
  let dateArr = date.split('/')
  const formattedDate: string[] = []
  if (dateArr?.length < 3) dateArr = date.split('-')
  if (dateArr?.length < 3) return ''
  formattedDate[0] = dateArr.find((s) => s.length === 4) || ''
  formattedDate[1] = dateArr[1]
  formattedDate[2] =
    dateArr.find((s, index) => index !== 1 && s.length !== 4) || ''
  if (formattedDate[1]?.length === 1) formattedDate[1] = `0${formattedDate[1]}`

  if (formattedDate[2]?.length === 1) formattedDate[2] = `0${formattedDate[2]}`
  return formattedDate.join('-')
}

export const getDayByKey: (key: string) => string = (key) => {
  const days: { [x: string]: string } = {
    L: 'Lunes',
    M: 'Martes',
    R: 'Miércoles',
    J: 'Jueves',
    V: 'Viernes',
    S: 'Sábado',
    D: 'Domingo'
  }

  return days[key]
}

export const formatBytes: (
  bytes: number,
  decimals?: number
) => {
  formatedBytes: number
  unit: GeneralTypes.UnitOfByte
} = (bytes, decimals = 2) => {
  if (!+bytes) return { formatedBytes: 0, unit: 'bytes' }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes: GeneralTypes.UnitOfByte[] = [
    'bytes',
    'kb',
    'mb',
    'gb',
    'tb',
    'pb',
    'eb',
    'zb',
    'yb'
  ]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return {
    formatedBytes: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    unit: sizes[i]
  }
}

type AnyObject = { [key: string]: any }

export const lowercaseKeys = (obj: AnyObject, deep = false) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[removeAccents(key).toLowerCase()] =
      deep && typeof obj[key] === 'object' ? lowercaseKeys(obj[key]) : obj[key]
    return acc
  }, {} as AnyObject)

export const getPercentage: (
  partialValue: number,
  totalValue: number
) => number = (partialValue, totalValue) => {
  return Math.trunc((100 * partialValue) / totalValue)
}

export const floatToPercentage = (
  num: number,
  decimalPlaces: number
): string => {
  const percentage = num.toFixed(decimalPlaces)
  return percentage + '%'
}

export const convertToFloat = (
  value: number | string | undefined,
  decimalPlaces: number
): string | undefined => {
  if (typeof value === 'string') {
    return parseFloat(value).toFixed(decimalPlaces)
  } else if (typeof value === 'number') {
    return value.toFixed(decimalPlaces)
  } else {
    return undefined
  }
}
