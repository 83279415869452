/* eslint-disable prettier/prettier */
export const SET_FORMAT_PROMOTION_REGISTER_FORM = 'SET_FORMAT_PROMOTION_REGISTER_FORM'
export const SET_PROMOTION_ID = 'SET_PROMOTION_ID'
export const SET_SELECTED_GROUPERS = 'SET_SELECTED_GROUPERS'
export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS'
export const SET_SELECTED_ADDITIONAL_PRODUCTS = 'SET_SELECTED_ADDITIONAL_PRODUCTS'

export const SET_PROMOTIONS_SEARCH_GROUPER = 'SET_PROMOTIONS_SEARCH_GROUPER'
export const SET_PROMOTIONS_NAME = 'SET_PROMOTIONS_NAME'
export const SET_PROMOTIONS_DESCRIPTION = 'SET_PROMOTIONS_DESCRIPTION'
export const SET_PROMOTIONS_BEGIN_DATE = 'SET_PROMOTIONS_BEGIN_DATE'
export const SET_PROMOTIONS_END_DATE = 'SET_PROMOTIONS_END_DATE'
export const SET_PROMOTIONS_CHOOSE_PRODUCT = 'SET_PROMOTIONS_CHOOSE_PRODUCT'
export const SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT = 'SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT'
export const SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY = 'SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY'
export const SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_QUANTITY = 'SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_QUANTITY'
export const SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE = 'SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE'
export const SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_PRICE = 'SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_PRICE'

export const SET_PROMOTIONS_SEARCH_GROUPER_ERRORS = 'SET_PROMOTIONS_SEARCH_GROUPER_ERRORS'
export const SET_PROMOTIONS_NAME_ERRORS = 'SET_PROMOTIONS_NAME_ERRORS'
export const SET_PROMOTIONS_DESCRIPTION_ERRORS = 'SET_PROMOTIONS_DESCRIPTION_ERRORS'
export const SET_PROMOTIONS_BEGIN_DATE_ERRORS = 'SET_PROMOTIONS_BEGIN_DATE_ERRORS'
export const SET_PROMOTIONS_END_DATE_ERRORS = 'SET_PROMOTIONS_END_DATE_ERRORS'
export const SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_ERRORS = 'SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_ERRORS'
export const SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_QUANTITY_ERRORS = 'SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_QUANTITY_ERRORS'
export const SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_PRICE_ERRORS = 'SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_PRICE_ERRORS'
export const SET_PROMOTIONS_CHOOSE_PRODUCT_ERRORS = 'SET_PROMOTIONS_CHOOSE_PRODUCT_ERRORS'
export const SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY_ERRORS = 'SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY_ERRORS'
export const SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE_ERRORS = 'SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE_ERRORS'

export const SET_INFO_PROMOTION_MODAL_VISIBLE = 'SET_INFO_PROMOTION_MODAL_VISIBLE'
export const SET_GRAL_INFO_PROMOTION_MODAL_VISIBLE = 'SET_GRAL_INFO_PROMOTION_MODAL_VISIBLE'

export const SET_PROMOTIONS_BEGIN_HOUR = 'SET_PROMOTIONS_BEGIN_HOUR'
export const SET_PROMOTIONS_END_HOUR = 'SET_PROMOTIONS_END_HOUR'
export const SET_PROMOTIONS_BEGIN_HOUR_ERRORS = 'SET_PROMOTIONS_BEGIN_HOUR_ERRORS'
export const SET_PROMOTIONS_END_HOUR_ERRORS = 'SET_PROMOTIONS_END_HOUR_ERRORS'
export const SET_DAYS_OF_WEEK_OPTIONS = 'SET_DAYS_OF_WEEK_OPTIONS'
export const SET_HOURS_OF_DAY_OPTIONS = 'SET_HOURS_OF_DAY_OPTIONS'
export const SET_SPECIFIC_DAYS_OF_WEEK = 'SET_SPECIFIC_DAYS_OF_WEEK'

export const SET_IS_ACTIVE_PROMOTION = 'SET_IS_ACTIVE_PROMOTION'

export const SET_VISIBLE_MODAL_ALERT= 'SET_VISIBLE_MODAL_ALERT'

/*
  * * BUNDLE PROMOTIONS
*/
export const SET_BUNDLE_PROMOTIONS_BASE_PRODUCT = 'SET_BUNDLE_PROMOTIONS_BASE_PRODUCT'
export const SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_QUANTITY = 'SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_QUANTITY'
export const SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_DISCOUNT_TYPE = 'SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_DISCOUNT_TYPE'
export const SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_VALUE = 'SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_VALUE'

export const SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT = 'SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT'
export const SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_QUANTITY = 'SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_QUANTITY'
export const SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_DISCOUNT_TYPE = 'SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_DISCOUNT_TYPE'
export const SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_VALUE = 'SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_VALUE'

export const SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT = 'SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT'
export const SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_QUANTITY = 'SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_QUANTITY'
export const SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_DISCOUNT_TYPE = 'SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_DISCOUNT_TYPE'
export const SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_VALUE = 'SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_VALUE'

export const SET_BUNDLE_PROMOTIONS_ADITIONAL_INFORMATION = 'SET_BUNDLE_PROMOTIONS_ADITIONAL_INFORMATION'

export const SET_BUNDLE_PROMOTIONS_MODAL_CREATE_THIRD_LEVEL_VISIBILITY = 'SET_BUNDLE_PROMOTIONS_MODAL_CREATE_THIRD_LEVEL_VISIBILITY'
export const SET_BUNDLE_PROMOTIONS_THIRD_LEVEL_NAME = 'SET_BUNDLE_PROMOTIONS_THIRD_LEVEL_NAME'

export const SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL = 'SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL'
export const SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT = 'SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT'
export const SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_DELETE = 'SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_DELETE'
export const SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_QUANTITY = 'SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_QUANTITY'
export const SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_DISCOUNT_TYPE = 'SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_DISCOUNT_TYPE'
export const SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_VALUE = 'SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_VALUE'
export const SET_BUNDLE_PROMOTIONS_SELECTED_EXTRA_LEVEL_PRODUCTS = 'SET_BUNDLE_PROMOTIONS_SELECTED_EXTRA_LEVEL_PRODUCTS'

export const SET_BUNDLE_PROMOTIONS_MODAL_API_RESPONSE_VISIBILITY = 'SET_BUNDLE_PROMOTIONS_MODAL_API_RESPONSE_VISIBILITY'
export const SET_BUNDLE_PROMOTIONS_MODAL_API_RESPONSE_MESSAGE = 'SET_BUNDLE_PROMOTIONS_MODAL_API_RESPONSE_MESSAGE'

export const SET_BUNDLE_PROMOTIONS_SELECTED_BASE_PRODUCTS = 'SET_BUNDLE_PROMOTIONS_SELECTED_BASE_PRODUCTS'
export const SET_BUNDLE_PROMOTIONS_SELECTED_COMPLEMENT_PRODUCTS = 'SET_BUNDLE_PROMOTIONS_SELECTED_COMPLEMENT_PRODUCTS'
export const SET_BUNDLE_PROMOTIONS_SELECTED_THIRD_PRODUCTS = 'SET_BUNDLE_PROMOTIONS_SELECTED_THIRD_PRODUCTS'

export const SET_BUNDLE_PROMOTIONS_MODAL_DELETE_THIRD_LEVEL_VISIBILITY = 'SET_BUNDLE_PROMOTIONS_MODAL_DELETE_THIRD_LEVEL_VISIBILITY'

export const SET_BUNDLE_PROMOTIONS_FORMAT = 'SET_BUNDLE_PROMOTIONS_FORMAT'
export const SET_BUNDLE_PROMOTIONS_THIRD_LEVEL_FORMAT = 'SET_BUNDLE_PROMOTIONS_THIRD_LEVEL_FORMAT'

// ---- BULK LOAD
export const SET_BULKLOAD_CURRENT_FILE = 'SET_BULKLOAD_CURRENT_FILE'
export const SET_BULKLOAD_MODAL_CONFIRMATION_VISIBILITY = 'SET_BULKLOAD_MODAL_CONFIRMATION_VISIBILITY'
export const SET_BULKLOAD_PROGRESS_BAR = 'SET_BULKLOAD_PROGRESS_BAR'
export const SET_BULKLOAD_ERRORS_MODAL_VISIBILITY = 'SET_BULKLOAD_ERRORS_MODAL_VISIBILITY'
export const SET_BULKLOAD_ERRORS_MODAL_CLOSE = 'SET_BULKLOAD_ERRORS_MODAL_CLOSE'
export const SET_BULKLOAD_ERRORS_MODAL_TYPE = 'SET_BULKLOAD_ERRORS_MODAL_TYPE'
export const SET_BULKLOAD_ERRORS_MODAL_PROMOTION_NAME = 'SET_BULKLOAD_ERRORS_MODAL_PROMOTION_NAME'
export const SET_BULKLOAD_ERRORS_MODAL_ERROR_DETAILS = 'SET_BULKLOAD_ERRORS_MODAL_ERROR_DETAILS'
export const SET_BULKLOAD_ERRORS_MODAL_RECORD_NUM = 'SET_BULKLOAD_ERRORS_MODAL_RECORD_NUM'
export const SET_BULKLOAD_PROGRESS_CIRCLE = 'SET_BULKLOAD_PROGRESS_CIRCLE'

// ---- BULK LOAD [forms]
export const SET_BULKLOAD_ERRORS_FORM_PROMOTION_NAME = 'SET_BULKLOAD_ERRORS_FORM_PROMOTION_NAME'
export const SET_BULKLOAD_ERRORS_FORM_SKU = 'SET_BULKLOAD_ERRORS_FORM_SKU'
export const SET_BULKLOAD_ERRORS_FORM_MECHANIC_QUANTITY = 'SET_BULKLOAD_ERRORS_FORM_MECHANIC_QUANTITY'
export const SET_BULKLOAD_ERRORS_FORM_MECHANIC_PRICE = 'SET_BULKLOAD_ERRORS_FORM_MECHANIC_PRICE'
export const SET_BULKLOAD_ERRORS_FORM_LOCATION = 'SET_BULKLOAD_ERRORS_FORM_LOCATION'
export const SET_BULKLOAD_ERRORS_FORM_BEGIN_DATE = 'SET_BULKLOAD_ERRORS_FORM_BEGIN_DATE'
export const SET_BULKLOAD_ERRORS_FORM_END_DATE = 'SET_BULKLOAD_ERRORS_FORM_END_DATE'
export const SET_BULKLOAD_ERRORS_FORM_MORE_ERRORS_MODAL_VISIBILITY = 'SET_BULKLOAD_ERRORS_FORM_MORE_ERRORS_MODAL_VISIBILITY'
// ---- BULK LOAD [forms errors]
export const SET_BULKLOAD_ERRORS_FORM_PROMOTION_NAME_ERRORS = 'SET_BULKLOAD_ERRORS_FORM_PROMOTION_NAME_ERRORS'
export const SET_BULKLOAD_ERRORS_FORM_SKU_ERRORS = 'SET_BULKLOAD_ERRORS_FORM_SKU_ERRORS'
export const SET_BULKLOAD_ERRORS_FORM_MECHANIC_QUANTITY_ERRORS = 'SET_BULKLOAD_ERRORS_FORM_MECHANIC_QUANTITY_ERRORS'
export const SET_BULKLOAD_ERRORS_FORM_MECHANIC_PRICE_ERRORS = 'SET_BULKLOAD_ERRORS_FORM_MECHANIC_PRICE_ERRORS'
export const SET_BULKLOAD_ERRORS_FORM_LOCATION_ERRORS = 'SET_BULKLOAD_ERRORS_FORM_LOCATION_ERRORS'
export const SET_BULKLOAD_ERRORS_FORM_BEGIN_DATE_ERRORS = 'SET_BULKLOAD_ERRORS_FORM_BEGIN_DATE_ERRORS'
export const SET_BULKLOAD_ERRORS_FORM_END_DATE_ERRORS = 'SET_BULKLOAD_ERRORS_FORM_END_DATE_ERRORS'
export const SET_BULKLOAD_ERRORS_FORM_SELECTED_GROUPERS = 'SET_BULKLOAD_ERRORS_FORM_SELECTED_GROUPERS'
