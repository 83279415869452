const partnerErrorMessages = [
  {
    en: 'The Email is already registered',
    es: 'El correo electónico ya ha sido registrado.'
  },
  {
    en: 'The mobile phone is registered.',
    es: 'El número celular ya ha sido registrado.'
  },
  {
    en: 'The TaxID is already registered',
    es: 'El RFC ya ha sido registrado.'
  },
  {
    en: 'The CURP is already registered',
    es: 'El CURP ya ha sido registrado'
  },
  {
    en: 'Error: Postal code is not found',
    es: 'Error: Código postal no encontrado'
  },
  {
    en: 'The TaxID is already registered',
    es: 'Error al crear empresario, el RFC ya ha sido registrado'
  },
  {
    en: 'Error: Postal code is invalid',
    es: 'El código postaL es inválido, prueba con otro'
  },
  {
    en: 'Error: Error from Copomex API',
    es: 'El formato es incorrecto, ingresa sólo números'
  },
  {
    en: "store hasn't device",
    es: 'La tienda no tiene dispositivo'
  },
  {
    en: 'The user does not exist',
    es: 'El usuario no existe'
  },
  {
    en: 'Please Enter a valid phone',
    es: 'Ingrese un número telefónico válido'
  },
  {
    en: 'Please enter a valid middle name',
    es: 'Ingresa un apellido paterno válido'
  },
  {
    en: 'Please enter a valid family name',
    es: 'Ingresa un apellido materno válido'
  },
  {
    en: 'Please enter a valid name',
    es: 'Ingresa un nombre válido'
  },
  {
    en: 'This SAP ID is already registered.',
    es: 'El SAP ID ya ha sido registrado, intenta nuevamente'
  },
  {
    en: 'The seller already exists.',
    es: 'El Seller ID ya está asignado a otro Modelorama'
  }
]

export default partnerErrorMessages
