// Assets
import * as actionTypes from '../actionTypes'

// Types
import FluxTypes from '../../../types/FluxTypes'

export const setCustomerData: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_CUSTOMER_DATA,
  value
})
