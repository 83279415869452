// Packages
import _ from 'lodash'
import React from 'react'

// Assets
import globalActionCreators from '../../actions'

// Components
import WithFluxActionsContextProvider from '../WithFluxActionsContextProvider'
import WithFluxStoreContextProvider from '../WithFluxStoreContextProvider'

// Types
import FluxTypes from '../../types/FluxTypes'

const WithFluxContextProviders: React.FC = ({ children }) => {
  const globalState: { [x: string]: FluxTypes.State } = {}
  const globalDispatchers: {
    name: string
    dispatch: React.Dispatch<FluxTypes.ActionObject>
  }[] = []

  // This function groups all the reducers' states under a single object that can be easily passed around
  const addToGlobalState = (state: FluxTypes.State, name: string) => {
    globalState[name] = state
  }

  // This function groups all the reducers' dispatchers under a single array to have access to all of them at the same time
  const addToGlobalDispatchers = (
    dispatch: React.Dispatch<FluxTypes.ActionObject>,
    name: string
  ) => {
    const index = _.findIndex(globalDispatchers, ['name', name])
    if (index !== -1) {
      globalDispatchers.splice(index, 1, { name, dispatch })
    } else {
      globalDispatchers.push({ name, dispatch })
    }
  }

  // This function receives an action and dispatches it to all the stores
  const globalDispatch = (action: FluxTypes.Action) => {
    // This condition basically does the same thing as the Redux Thunk middleware in Redux
    if (typeof action === 'function') {
      const resultAction = action(globalDispatch, globalState)
      if (typeof resultAction === 'object') {
        globalDispatchers.forEach(({ dispatch }) => dispatch(resultAction))
      }
    } else {
      globalDispatchers.forEach(({ dispatch }) => {
        dispatch(action)
      })
    }
  }

  return (
    <WithFluxActionsContextProvider
      globalActionCreators={globalActionCreators}
      globalDispatch={globalDispatch}
    >
      <WithFluxStoreContextProvider
        addToGlobalDispatchers={addToGlobalDispatchers}
        addToGlobalState={addToGlobalState}
        name="appStore"
      >
        {children}
      </WithFluxStoreContextProvider>
    </WithFluxActionsContextProvider>
  )
}

export default WithFluxContextProviders
