/* eslint-disable prettier/prettier */
export const SET_DELIVERY_WINDOWS_STORE_ID = 'SET_DELIVERY_WINDOWS_STORE_ID'
export const SET_DELIVERY_WINDOWS_CLIENT_ID = 'SET_DELIVERY_WINDOWS_CLIENT_ID'
export const SET_DELIVERY_WINDOWS_DELIVERY_DAYS = 'SET_DELIVERY_WINDOWS_DELIVERY_DAYS'

export const SET_DELIVERY_WINDOWS_STORE_ID_ERRORS = 'SET_DELIVERY_WINDOWS_STORE_ID_ERRORS'
export const SET_DELIVERY_WINDOWS_CLIENT_ID_ERRORS = 'SET_DELIVERY_WINDOWS_CLIENT_ID_ERRORS'
export const SET_DELIVERY_WINDOWS_DELIVERY_DAYS_ERRORS = 'SET_DELIVERY_WINDOWS_DELIVERY_DAYS_ERRORS'

export const SET_CURRENT_SELECTED_ROW = 'SET_CURRENT_SELECTED_ROW'
export const SET_ORIGINAL_SELECTED_ROW = 'SET_ORIGINAL_SELECTED_ROW'
export const SET_MODAL_EDITION_VISIBLE = 'SET_MODAL_EDITION_VISIBLE'

export const SET_MODAL_CHANGE_STATUS_VISIBLE = 'SET_MODAL_CHANGE_STATUS_VISIBLE'

export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS'
export const SET_SELECTED_ROW_KEYS = 'SET_SELECTED_ROW_KEYS'
export const SET_SELECTED_ROWS_MODAL_CHANGE_STATUS = 'SET_SELECTED_ROWS_MODAL_CHANGE_STATUS'

