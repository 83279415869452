// Packages
import { Observable } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import { navigate } from 'gatsby'

// Assets
import { deleteParametersUser } from '../../utils/localStorage'

const signOutSession = () => {
  signOut()
  deleteParametersUser()
  navigate('/')
}

const errorLink = onError(
  ({ networkError, graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { extensions, message, locations, path } = err
        switch (extensions?.code) {
          case 'UNAUTHENTICATED': {
            return new Observable((observer) => {
              fetchAuthSession({ forceRefresh: true })
                .then((data) => {
                  if (typeof data.tokens === 'undefined') signOutSession()
                  typeof window !== 'undefined' &&
                    window.localStorage.setItem(
                      'idToken',
                      String(data.tokens?.idToken?.toString())
                    )
                  typeof window !== 'undefined' &&
                    window.localStorage.setItem(
                      'accessToken',
                      String(data.tokens?.accessToken?.toString())
                    )
                  typeof window !== 'undefined' &&
                    window.localStorage.setItem(
                      'refreshToken',
                      String(data.tokens?.accessToken?.toString())
                    )
                  operation.setContext(({ headers }: { headers: Headers }) => {
                    const newHeaders = {
                      ...headers,
                      authorization: `Bearer ${data.tokens?.accessToken?.toString()}`
                    }
                    return newHeaders
                  })
                })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer)
                  }
                  forward(operation).subscribe(subscriber)
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err)
                  observer.error(err)
                  signOutSession()
                })
            })
          }
          default: {
            // eslint-disable-next-line no-console
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ` +
                `${locations}, Path: ${path}`
            )
          }
        }
      }
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}`)
    }
  }
)

export default errorLink
