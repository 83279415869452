// Packages
import { getCurrentUser } from 'aws-amplify/auth'
import { navigate } from 'gatsby'
import React, { createContext } from 'react'

// Context
import {
  APP_PAM_CAT_PRODUCT,
  APP_PAM_CAT_PROMOTIONS,
  APP_PAM_PARTNER,
  APP_PAM_STORE,
  APP_PAM_USERS,
  APP_PAM_DEVICE,
  APP_PAM_ORDERS_MAW,
  APP_PAM_MAM
} from '../../data/constants/userRoles'
import useUserRoles from '../../hooks/useUserRoles'

interface UserType {
  attributes: {
    sub: string
  }
  username: string
}

interface contextProps {
  userRoles: any
  userRolesLoading: boolean
  getPermissionByModule: (moduleId: string) => {
    hasPermission: boolean
    writeAllowed: boolean
  }
  refetchRoles: () => void
}

export const authContext = createContext<contextProps>({} as contextProps)

const WithAuthContextProvider: React.FC = ({ children }) => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

  const [user, setUser] = React.useState<UserType | null>(null)

  const getAuthUser = async () => {
    if (localStorage.getItem('idToken'))
      getCurrentUser().then((user) => {
        setUser({
          attributes: {
            sub: user.userId
          },
          username: user.username
        })
      })
  }

  React.useEffect(() => {
    if (!pathname.includes('/login')) {
      getAuthUser()
    }
  }, [pathname])

  const { userRoles, loading: userRolesLoading } = useUserRoles(user)

  const getPermissionByModule = (moduleId: string) => {
    const finded = userRoles.find((p: any) => p.id === moduleId)
    const permissions = {
      hasPermission: finded ? true : false,
      writeAllowed: finded?.writeAllowed
    }
    return permissions
  }

  React.useEffect(() => {
    if (!userRoles) return

    redirectByRole(userRoles, pathname)
  }, [userRoles, pathname])

  const redirectToAllowed = (roles: any) => {
    roles.forEach((item: any) => {
      switch (item.id) {
        case APP_PAM_PARTNER:
          navigate('/partners/', { replace: true })
          return
        case APP_PAM_STORE:
          navigate('/stores/', { replace: true })
          return
        case APP_PAM_CAT_PROMOTIONS:
          navigate('/promotions/', { replace: true })
          return
        case APP_PAM_CAT_PRODUCT:
          navigate('/catalog/', { replace: true })
          return
        case APP_PAM_USERS:
          navigate('/users/', { replace: true })
          return
        case APP_PAM_DEVICE:
          navigate('/devices/', { replace: true })
          return
        case APP_PAM_ORDERS_MAW:
          navigate('/orders/', { replace: true })
          return
        // case APP_PAM_ORDERS_MAW: // TODO: implement to own role
        //   navigate('/delivery-windows/', { replace: true })
        //   return
        case APP_PAM_MAM:
          navigate('/mam/', { replace: true })
          return
        default:
          navigate('/login', { replace: true })
          return
      }
    })
  }

  const redirectByRole = (roles: any, url: string) => {
    let finded
    if (url.includes('partners')) {
      finded = roles.find((role: any) => role.id === APP_PAM_PARTNER)
      if (finded) return
      else redirectToAllowed(roles)
    }
    if (url.includes('stores')) {
      finded = roles.find((role: any) => role.id === APP_PAM_STORE)
      if (finded) return
      else redirectToAllowed(roles)
    }
    if (url.includes('promotions')) {
      finded = roles.find((role: any) => role.id === APP_PAM_CAT_PROMOTIONS)
      if (finded) return
      else redirectToAllowed(roles)
    }
    if (url.includes('catalog')) {
      finded = roles.find((role: any) => role.id === APP_PAM_CAT_PRODUCT)
      if (finded) return
      else redirectToAllowed(roles)
    }
    if (url.includes('users')) {
      finded = roles.find((role: any) => role.id === APP_PAM_USERS)
      if (finded) return
      else redirectToAllowed(roles)
    }
    if (url.includes('devices')) {
      finded = roles.find((role: any) => role.id === APP_PAM_DEVICE)
      if (finded) return
      else redirectToAllowed(roles)
    }
    if (url.includes('orders')) {
      finded = roles.find((role: any) => role.id === APP_PAM_ORDERS_MAW)
      if (finded) return
      else redirectToAllowed(roles)
    }
    if (url.includes('delivery-windows')) {
      finded = roles.find((role: any) => role.id === APP_PAM_ORDERS_MAW) // TODO: change to own role
      if (finded) return
      else redirectToAllowed(roles)
    }
    if (url.includes('mam')) {
      finded = roles.find((role: any) => role.id === APP_PAM_MAM)
      if (finded) return
      else redirectToAllowed(roles)
    }
  }

  return (
    <authContext.Provider
      value={{
        userRoles,
        userRolesLoading,
        getPermissionByModule,
        refetchRoles: getAuthUser
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export default WithAuthContextProvider
