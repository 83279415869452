// Types
import GrouperTypes from '../../../types/GrouperTypes'

const typeofGroupers: { [k: string]: GrouperTypes.GrouperType } = {
  STRATEGICUNIT: 'UEN',
  STORE: 'Store',
  SALESREGION: 'DRV',
  AGENCY: 'SAG',
  PRICEZONE: 'ZP'
}

export default typeofGroupers
