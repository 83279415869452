// Assets
import * as actionTypes from '../actionTypes'
import FluxTypes from '../../../types/FluxTypes'

export const setDeliveryWindowsStoreID: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_DELIVERY_WINDOWS_STORE_ID,
  value
})

export const setDeliveryWindowsClientID: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_DELIVERY_WINDOWS_CLIENT_ID,
  value
})

export const setDeliveryWindowsDeliveryDays: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_DELIVERY_WINDOWS_DELIVERY_DAYS,
  value
})

export const setDeliveryWindowsStoreIDErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_DELIVERY_WINDOWS_STORE_ID_ERRORS,
  errors
})

export const setDeliveryWindowsClientIDErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_DELIVERY_WINDOWS_CLIENT_ID_ERRORS,
  errors
})

export const setDeliveryWindowsDeliveryDaysErrors: FluxTypes.ActionCreator = (
  errors
) => ({
  type: actionTypes.SET_DELIVERY_WINDOWS_DELIVERY_DAYS_ERRORS,
  errors
})

export const setCurrentSelectedRow: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_CURRENT_SELECTED_ROW,
  value
})

export const setOriginalSelectedRow: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_ORIGINAL_SELECTED_ROW,
  value
})

export const setModalEditionVisible: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_MODAL_EDITION_VISIBLE,
  value
})

export const setModalChangeStatus: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_MODAL_CHANGE_STATUS_VISIBLE,
  value
})

export const setSelectedRows: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_SELECTED_ROWS,
  value
})

export const setSelectedRowKeys: FluxTypes.ActionCreator = (value) => ({
  type: actionTypes.SET_SELECTED_ROW_KEYS,
  value
})

export const setSelectedRowsModalChangeStatus: FluxTypes.ActionCreator = (
  value
) => ({
  type: actionTypes.SET_SELECTED_ROWS_MODAL_CHANGE_STATUS,
  value
})
