export const device = {
  downloadLogs: 'Descarga de logs',
  installedAplications: 'Aplicaciones instaladas',
  SubTitleDeviceId: 'Terminal:',
  SubTitleLocation: 'Ubicación:',
  saveLog: 'Guardando log'
}

export const app = {
  createDrawerTitle: 'Crear nueva aplicación',
  editDrawerTitle: 'Nombre de la aplicación',
  versionHistoryTab: 'Historial de versiones',
  createNewVersionTab: 'Crear nueva versión',
  saveApk: 'Guardando apk',
  saveNewVersion: 'Creando nueva aplicación',
  updateVersion: 'Actualizando la versión',
  downloadApk: 'Descargando apk',
  nameLabel: 'Nombre de la aplicación*',
  nameRequired: 'Ingresa el nombre de la aplicación',
  namePlaceHolder: 'Escribe el nombre de la nueva app',
  version: 'Versión*',
  onlyDotsAndNumbers: 'Solo puntos y números',
  versionRequired: 'Ingresa la versión de la aplicación',
  onlyNumbersAndMidleDots: 'Solo se permiten números y puntos intermedios',
  versionPlaceholder: 'Define la versión de la app',
  packageName: 'Nombre del paquete*',
  packageNameRequired: 'Ingresa el nombre del paquete',
  packageNamePlaceholder: 'Define el nombre del paquete',
  description: 'Descripción*',
  descriptionRequired: 'Ingresa la descripción de la aplicación',
  descriptionPlaceholder: 'Escribe la descripción de la app',
  releaseNote: 'Release note*',
  releaseNoteRequired: 'Ingresa las notas de la liberación de la app',
  releaseNotePlaceholder: 'Escribe las notas de liberación de la app',
  addFile: 'Agregar archivo*',
  addFileLimit: '5MB',
  addFilePlaceholder: 'Sube el archivo apk.',
  configuration: 'Configuración de la versión',
  regexEspecialCharacters: 'No se permiten carácteres especiales',
  isBaseLineVersion: 'Versión base',
  isMandatory: 'Instalación obligatoria',
  needUninstall: 'Desinstalación obligatoria',
  needLogOut: 'Cierre de sesión obligatorio',
  createNewAppButton: 'Crear nueva aplicación',
  createNewVersionButton: 'Crear nueva versión'
}

export const group = {
  snOnPosInvalid: 'Hay SN de POS no validos en el archivo',
  createDrawerTitle: 'Crear nuevo grupo',
  editDrawerTitle: 'Editar grupo',
  createNewGroup: 'Creando nuevo grupo',
  updateGroup: 'Actualizando grupo',
  saveCsv: 'Guardando csv',
  editGroupTab: 'Editar grupo',
  addAppsTab: 'Agregar aplicaciones',
  nameLabe: 'Nombre del grupo*',
  nameRequired: 'Ingresa el nombre del grupo',
  namePlaceHolder: 'Escribe el nombre del nuevo grupo',
  description: 'Descripción del grupo*',
  descriptionRequired: 'Ingresa la descripción para el grupo',
  descriptionPlaceholder: 'Agrega una descripción para el grupo',
  regexEspecialCharacters: 'No se permiten carácteres especiales',
  addFile: 'Agregar archivo*',
  addFilePlaceholder: 'Sube el archivo .csv',
  availableApps: 'Aplicaciones disponible en el grupo',
  createNewGroupButton: 'Crear nuevo grupo',
  saveChangesButton: 'Guardar cambios',
  modalEditConfirmButton: 'Confirmar',
  modalEditCancelButton: 'Cancelar',
  modalEditTitle: 'Actualización de terminales',
  modalEditMessage:
    'Recuerda que todas las terminales pertenecientes al grupo se actualizarán con las versiones y cambios efectuados'
}
