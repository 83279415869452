// Packages
import moment from 'moment'

// Assets
import { standardStringFilter, onlyDigitsFilter } from '../../utils/forms'
import * as actionTypes from '../../actions/promotions/actionTypes'

// Types
import PromotionsStoreTypes from '../../types/PromotionsStoreTypes'
import FluxTypes from '../../types/FluxTypes'

const today = new Date()

const reducer: (
  state: PromotionsStoreTypes.PromotionsState,
  action: FluxTypes.ActionObject
) => PromotionsStoreTypes.PromotionsState = (state, action) => {
  let newState = state

  switch (action.type) {
    case actionTypes.SET_SELECTED_GROUPERS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          selectedGroupers: action.value
        }
      }
      break
    }
    case actionTypes.SET_SELECTED_PRODUCTS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          selectedProducts: action.value
        }
      }
      break
    }

    case actionTypes.SET_SELECTED_ADDITIONAL_PRODUCTS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          selectedAdditionalProducts: action.value
        }
      }
      break
    }
    case actionTypes.SET_FORMAT_PROMOTION_REGISTER_FORM: {
      newState = {
        ...state,
        register: {
          ...state.register,
          searchGrouper: {
            ...state.register.searchGrouper,
            value: '',
            errors: []
          },
          name: {
            ...state.register.name,
            value: '',
            errors: []
          },
          description: {
            ...state.register.description,
            value: '',
            errors: []
          },
          beginDate: {
            ...state.register.beginDate,
            value: moment(today, 'DD/MM/YYYY'),
            errors: []
          },
          endDate: {
            ...state.register.endDate,
            value: '',
            errors: []
          },
          beginHour: {
            ...state.register.beginHour,
            value: '',
            errors: []
          },
          endHour: {
            ...state.register.endHour,
            value: '',
            errors: []
          },
          chooseProduct: {
            ...state.register.chooseProduct,
            value: '',
            errors: []
          },
          chooseProductAditional: {
            ...state.register.chooseProductAditional,
            value: '',
            errors: []
          },
          chooseProductQuantity: {
            ...state.register.chooseProductQuantity,
            value: '',
            errors: []
          },
          chooseProductPrice: {
            ...state.register.chooseProductPrice,
            value: '',
            errors: []
          },
          selectedGroupers: [],
          selectedProducts: [],
          specificDaysOfWeek: [],
          daysOfWeekOptions: 'all-week',
          hoursOfDayOptions: 'all-day'
        }
      }
      break
    }
    case actionTypes.SET_IS_ACTIVE_PROMOTION: {
      newState = {
        ...state,
        register: {
          ...state.register,
          isActive: action.value
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_SEARCH_GROUPER: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 60
      })
      newState = {
        ...state,
        register: {
          ...state.register,
          searchGrouper: {
            ...state.register.searchGrouper,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_NAME: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 100
      })
      newState = {
        ...state,
        register: {
          ...state.register,
          name: {
            ...state.register.name,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_DESCRIPTION: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 200
      })
      newState = {
        ...state,
        register: {
          ...state.register,
          description: {
            ...state.register.description,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_BEGIN_DATE: {
      newState = {
        ...state,
        register: {
          ...state.register,
          beginDate: {
            ...state.register.beginDate,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_END_DATE: {
      newState = {
        ...state,
        register: {
          ...state.register,
          endDate: {
            ...state.register.endDate,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 120
      })
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseProduct: {
            ...state.register.chooseProduct,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY: {
      const filteredValue = onlyDigitsFilter(action.value)
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseProductQuantity: {
            ...state.register.chooseProductQuantity,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE: {
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseProductPrice: {
            ...state.register.chooseProductPrice,
            value: action.value
          }
        }
      }
      break
    }

    case actionTypes.SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 120
      })
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseAdditionalProduct: {
            ...state.register.chooseAdditionalProduct,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_QUANTITY: {
      const filteredValue = onlyDigitsFilter(action.value)
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseAdditionalProductQuantity: {
            ...state.register.chooseAdditionalProductQuantity,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_CHOOSE_ADDITIONAL_PRODUCT_PRICE: {
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseAdditionalProductPrice: {
            ...state.register.chooseAdditionalProductPrice,
            value: action.value
          }
        }
      }
      break
    }

    case actionTypes.SET_PROMOTIONS_BEGIN_HOUR: {
      newState = {
        ...state,
        register: {
          ...state.register,
          beginHour: {
            ...state.register.beginHour,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_END_HOUR: {
      newState = {
        ...state,
        register: {
          ...state.register,
          endHour: {
            ...state.register.endHour,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_DAYS_OF_WEEK_OPTIONS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          daysOfWeekOptions: action.value
        }
      }
      break
    }
    case actionTypes.SET_HOURS_OF_DAY_OPTIONS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          hoursOfDayOptions: action.value
        }
      }
      break
    }
    case actionTypes.SET_SPECIFIC_DAYS_OF_WEEK: {
      newState = {
        ...state,
        register: {
          ...state.register,
          specificDaysOfWeek: action.value
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_BEGIN_HOUR_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          beginHour: {
            ...state.register.beginHour,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_END_HOUR_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          endHour: {
            ...state.register.endHour,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_SEARCH_GROUPER_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          searchGrouper: {
            ...state.register.searchGrouper,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_NAME_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          name: {
            ...state.register.name,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_DESCRIPTION_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          description: {
            ...state.register.description,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_BEGIN_DATE_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          beginDate: {
            ...state.register.beginDate,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_END_DATE_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          endDate: {
            ...state.register.endDate,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseProduct: {
            ...state.register.chooseProduct,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_QUANTITY_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseProductQuantity: {
            ...state.register.chooseProductQuantity,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_PROMOTIONS_CHOOSE_PRODUCT_PRICE_ERRORS: {
      newState = {
        ...state,
        register: {
          ...state.register,
          chooseProductPrice: {
            ...state.register.chooseProductPrice,
            errors: action.errors
          }
        }
      }
      break
    }
    case actionTypes.SET_INFO_PROMOTION_MODAL_VISIBLE: {
      newState = {
        ...state,
        infoPromotionModal: {
          ...state.infoPromotionModal,
          isVisible: action.value
        }
      }
      break
    }
    case actionTypes.SET_GRAL_INFO_PROMOTION_MODAL_VISIBLE: {
      newState = {
        ...state,
        gralPromotionModal: {
          ...state.gralPromotionModal,
          isVisible: action.value
        }
      }
      break
    }
    case actionTypes.SET_VISIBLE_MODAL_ALERT: {
      newState = {
        ...state,
        modalAlert: action.value
      }
      break
    }

    /*
     * * BUNDLE PROMOTIONS
     */

    case actionTypes.SET_BUNDLE_PROMOTIONS_BASE_PRODUCT: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 120
      })
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          baseProduct: {
            ...state.registerBundle.baseProduct,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_QUANTITY: {
      const filteredValue = onlyDigitsFilter(action.value)
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          baseProductQuantity: {
            ...state.registerBundle.baseProductQuantity,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_DISCOUNT_TYPE: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          baseProductDiscountType: {
            ...state.registerBundle.baseProductDiscountType,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_BASE_PRODUCT_VALUE: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          baseProductValue: {
            ...state.registerBundle.baseProductValue,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 120
      })
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          complementProduct: {
            ...state.registerBundle.complementProduct,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_QUANTITY: {
      const filteredValue = onlyDigitsFilter(action.value)
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          complementProductQuantity: {
            ...state.registerBundle.complementProductQuantity,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_DISCOUNT_TYPE: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          complementProductDiscountType: {
            ...state.registerBundle.complementProductDiscountType,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_COMPLEMENT_PRODUCT_VALUE: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          complementProductValue: {
            ...state.registerBundle.complementProductValue,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT: {
      const filteredValue = standardStringFilter(action.value?.value || '', {
        maxLength: 120
      })
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          thirdProduct: {
            ...state.registerBundle.thirdProduct,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_QUANTITY: {
      const filteredValue = onlyDigitsFilter(action.value)
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          thirdProductQuantity: {
            ...state.registerBundle.thirdProductQuantity,
            value: filteredValue
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_DISCOUNT_TYPE: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          thirdProductDiscountType: {
            ...state.registerBundle.thirdProductDiscountType,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_PRODUCT_VALUE: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          thirdProductValue: {
            ...state.registerBundle.thirdProductValue,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_ADITIONAL_INFORMATION: {
      // const filteredValue = standardStringFilter(action.value)
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          additionalInformation: {
            ...state.registerBundle.additionalInformation,
            value: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_MODAL_CREATE_THIRD_LEVEL_VISIBILITY: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          modalCreateThirdLevel: {
            ...state.registerBundle.modalCreateThirdLevel,
            isVisible: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_LEVEL_NAME: {
      // const filteredValue = standardStringFilter(action.value, {
      //   maxLength: 100
      // })
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          modalCreateThirdLevel: {
            ...state.registerBundle.modalCreateThirdLevel,
            thirdLevelName: {
              ...state.registerBundle.modalCreateThirdLevel.thirdLevelName,
              value: action.value
            }
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          extraLevels: [...state.registerBundle.extraLevels, action.payload]
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT: {
      const filteredValue = standardStringFilter(action.payload.value || '', {
        maxLength: 120
      })
      const newExtraLevels = state.registerBundle.extraLevels
      newExtraLevels[action.payload.index].extraLevelProduct.value =
        filteredValue || ''
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          extraLevels: newExtraLevels
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_DELETE: {
      const newExtraLevels = state.registerBundle.extraLevels
      delete newExtraLevels[action.value]
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          extraLevels: newExtraLevels.filter((elements) => {
            return !!elements
          })
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_QUANTITY: {
      const filteredValue = onlyDigitsFilter(action.payload?.value)
      const newExtraLevels = state.registerBundle.extraLevels
      newExtraLevels[
        action.payload?.index || 0
      ].extraLevelProductQuantity.value = filteredValue || ''

      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          extraLevels: newExtraLevels
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_DISCOUNT_TYPE: {
      const newExtraLevels = state.registerBundle.extraLevels
      newExtraLevels[
        action.payload?.index || 0
      ].extraLevelProductDiscountType.value = action.payload?.value || ''

      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          extraLevels: newExtraLevels
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_EXTRA_LEVEL_PRODUCT_VALUE: {
      const newExtraLevels = state.registerBundle.extraLevels
      newExtraLevels[action.payload?.index || 0].extraLevelProductValue.value =
        action.payload?.value || ''

      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          extraLevels: newExtraLevels
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_SELECTED_EXTRA_LEVEL_PRODUCTS: {
      const newExtraLevels = state.registerBundle.extraLevels
      newExtraLevels[
        Number(action.payload.index) || 0
      ].selectedExtraLevelProducts = action.payload.value || []

      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          extraLevels: newExtraLevels
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_MODAL_API_RESPONSE_VISIBILITY: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          modalErrorAPIResponse: {
            ...state.registerBundle.modalErrorAPIResponse,
            isVisible: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_MODAL_API_RESPONSE_MESSAGE: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          modalErrorAPIResponse: {
            ...state.registerBundle.modalErrorAPIResponse,
            message: action.value
          }
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_SELECTED_BASE_PRODUCTS: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          selectedBaseProducts: action.value
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_SELECTED_COMPLEMENT_PRODUCTS: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          selectedComplementProducts: action.value
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_SELECTED_THIRD_PRODUCTS: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          selectedThirdProducts: action.value
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_MODAL_DELETE_THIRD_LEVEL_VISIBILITY: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          isDeleteThirdLevelModalVisible: action.value
        }
      }
      break
    }
    case actionTypes.SET_BUNDLE_PROMOTIONS_FORMAT: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          baseProduct: {
            ...state.registerBundle.baseProduct,
            value: '',
            errors: []
          },
          baseProductQuantity: {
            ...state.registerBundle.baseProductQuantity,
            value: '',
            errors: []
          },
          baseProductDiscountType: {
            ...state.registerBundle.baseProductDiscountType,
            value: '0',
            errors: []
          },
          baseProductValue: {
            ...state.registerBundle.baseProductValue,
            value: '',
            errors: []
          },
          complementProduct: {
            ...state.registerBundle.complementProduct,
            value: '',
            errors: []
          },
          complementProductQuantity: {
            ...state.registerBundle.complementProductQuantity,
            value: '',
            errors: []
          },
          complementProductDiscountType: {
            ...state.registerBundle.complementProductDiscountType,
            value: '0',
            errors: []
          },
          complementProductValue: {
            ...state.registerBundle.complementProductValue,
            value: '',
            errors: []
          },
          thirdProduct: {
            ...state.registerBundle.thirdProduct,
            value: '',
            errors: []
          },
          thirdProductQuantity: {
            ...state.registerBundle.thirdProductQuantity,
            value: '',
            errors: []
          },
          thirdProductDiscountType: {
            ...state.registerBundle.thirdProductDiscountType,
            value: '0',
            errors: []
          },
          thirdProductValue: {
            ...state.registerBundle.thirdProductValue,
            value: '',
            errors: []
          },
          additionalInformation: {
            ...state.registerBundle.additionalInformation,
            value: '',
            errors: []
          },
          modalCreateThirdLevel: {
            ...state.registerBundle.modalCreateThirdLevel,
            isVisible: false,
            thirdLevelName: {
              ...state.registerBundle.modalCreateThirdLevel.thirdLevelName,
              value: null,
              errors: []
            }
          },
          modalErrorAPIResponse: {
            ...state.registerBundle.modalErrorAPIResponse,
            isVisible: false,
            message: []
          },
          selectedBaseProducts: [],
          selectedComplementProducts: [],
          selectedThirdProducts: [],
          isDeleteThirdLevelModalVisible: false
        }
      }
      break
    }

    case actionTypes.SET_BUNDLE_PROMOTIONS_THIRD_LEVEL_FORMAT: {
      newState = {
        ...state,
        registerBundle: {
          ...state.registerBundle,
          thirdProduct: {
            ...state.registerBundle.thirdProduct,
            value: '',
            errors: []
          },
          thirdProductQuantity: {
            ...state.registerBundle.thirdProductQuantity,
            value: '',
            errors: []
          },
          thirdProductDiscountType: {
            ...state.registerBundle.thirdProductDiscountType,
            value: '0',
            errors: []
          },
          thirdProductValue: {
            ...state.registerBundle.thirdProductValue,
            value: '',
            errors: []
          },
          modalCreateThirdLevel: {
            ...state.registerBundle.modalCreateThirdLevel,
            isVisible: false,
            thirdLevelName: {
              ...state.registerBundle.modalCreateThirdLevel.thirdLevelName,
              value: null,
              errors: []
            }
          },
          selectedThirdProducts: [],
          extraLevels: []
        }
      }
      break
    }

    // ---- BULK LOAD
    case actionTypes.SET_BULKLOAD_CURRENT_FILE: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          currentFileToUpload: action.value
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_MODAL_CONFIRMATION_VISIBILITY: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          modalConfirmation: {
            ...state.bulkLoad.modalConfirmation,
            isVisible: action.value
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_PROGRESS_BAR: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          progressBar: {
            ...state.bulkLoad.progressBar,
            ...action.value
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_MODAL_VISIBILITY: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            modal: {
              ...state.bulkLoad.errors.modal,
              isVisible: action.value
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_MODAL_TYPE: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            modal: {
              ...state.bulkLoad.errors.modal,
              type: action.value
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_MODAL_PROMOTION_NAME: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            modal: {
              ...state.bulkLoad.errors.modal,
              currentPromotionName: action.value
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_MODAL_ERROR_DETAILS: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            modal: {
              ...state.bulkLoad.errors.modal,
              currentErrorDetails: action.value
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_MODAL_RECORD_NUM: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            modal: {
              ...state.bulkLoad.errors.modal,
              recordNum: action.value
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_MODAL_CLOSE: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            modal: {
              ...state.bulkLoad.errors.modal,
              isVisible: false,
              currentPromotionName: '',
              currentErrorDetails: '',
              recordNum: null
            },
            forms: {
              ...state.bulkLoad.errors.forms,
              promotionName: {
                ...state.bulkLoad.errors.forms.promotionName,
                value: '',
                errors: []
              },
              sku: {
                ...state.bulkLoad.errors.forms.sku,
                value: '',
                errors: []
              },
              mechanicQuantity: {
                ...state.bulkLoad.errors.forms.mechanicQuantity,
                value: '',
                errors: []
              },
              mechanicPrice: {
                ...state.bulkLoad.errors.forms.mechanicPrice,
                value: '',
                errors: []
              },
              location: {
                ...state.bulkLoad.errors.forms.location,
                value: '',
                errors: []
              },
              beginDate: {
                ...state.bulkLoad.errors.forms.beginDate,
                value: moment(today, 'DD/MM/YYYY'),
                errors: []
              },
              endDate: {
                ...state.bulkLoad.errors.forms.endDate,
                value: '',
                errors: []
              },
              selectedGroupers: []
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_PROGRESS_CIRCLE: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          progressCircle: {
            ...state.bulkLoad.progressCircle,
            ...action.value
          }
        }
      }
      break
    }

    // ---- BULK LOAD [forms]
    case actionTypes.SET_BULKLOAD_ERRORS_FORM_PROMOTION_NAME: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 100
      })
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              promotionName: {
                ...state.bulkLoad.errors.forms.promotionName,
                value: filteredValue
              }
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_SKU: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              sku: {
                ...state.bulkLoad.errors.forms.sku,
                value: action.value
              }
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_MECHANIC_QUANTITY: {
      const filteredValue = onlyDigitsFilter(action.value)
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              mechanicQuantity: {
                ...state.bulkLoad.errors.forms.mechanicQuantity,
                value: filteredValue
              }
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_MECHANIC_PRICE: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              mechanicPrice: {
                ...state.bulkLoad.errors.forms.mechanicPrice,
                value: action.value
              }
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_LOCATION: {
      const filteredValue = standardStringFilter(action.value, {
        maxLength: 60
      })
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              location: {
                ...state.bulkLoad.errors.forms.location,
                value: filteredValue
              }
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_BEGIN_DATE: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              beginDate: {
                ...state.bulkLoad.errors.forms.beginDate,
                value: action.value
              }
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_END_DATE: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              endDate: {
                ...state.bulkLoad.errors.forms.endDate,
                value: action.value
              }
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_SELECTED_GROUPERS: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              selectedGroupers: action.value
            }
          }
        }
      }
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_MORE_ERRORS_MODAL_VISIBILITY: {
      newState = {
        ...state,
        bulkLoad: {
          ...state.bulkLoad,
          errors: {
            ...state.bulkLoad.errors,
            forms: {
              ...state.bulkLoad.errors.forms,
              hasMoreErrorsModal: {
                ...state.bulkLoad.errors.forms.hasMoreErrorsModal,
                isVisible: action.value
              }
            }
          }
        }
      }
      break
    }

    // ---- BULK LOAD [forms errors]
    case actionTypes.SET_BULKLOAD_ERRORS_FORM_PROMOTION_NAME_ERRORS: {
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_SKU_ERRORS: {
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_MECHANIC_QUANTITY_ERRORS: {
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_MECHANIC_PRICE_ERRORS: {
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_LOCATION_ERRORS: {
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_BEGIN_DATE_ERRORS: {
      break
    }

    case actionTypes.SET_BULKLOAD_ERRORS_FORM_END_DATE_ERRORS: {
      break
    }

    default:
      return newState
  }

  return newState
}

export default reducer
