export const store = {
  mawInfo: 'Modelorama Advance Warehouse',
  sellerInfo:
    'Consulta el archivo de Directorio de Modeloramas TaDa e ingresa el nombre del seller localizado en la columna "Nombre VTEX"',
  title: '',
  titleSeller: 'Asignación nuevo TaDa Seller ID',
  message: '¿Deseas guardar los cambios?',
  messageSeller:
    'Administrador, este cambio puede ocasionar algunas inconsistencias en la reportería de Sell Out y ventas por TaDa de este Modelorama, además de inconsistencias en descuento de inventario por venta de órdenes TaDa.',
  secondMessage: '',
  secondMessageSeller: '¿Deseas guardar los cambios?'
}

export default store
